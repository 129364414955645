/**
 * ENV object for Next's' client and server
 * DefinePlugin replacement.
 */
const NEXTJS_ENV =
  typeof process !== 'undefined' && process?.env?.NEXT_PUBLIC_IS_NEXTJS
    ? {
        IS_NEXTJS: process.env.NEXT_PUBLIC_IS_NEXTJS,
        API_HOST: process.env.NEXT_PUBLIC_API_HOST,
        IMG_RESIZING_HOST: process.env.NEXT_PUBLIC_IMG_RESIZING_HOST,
        API_HOST_ADS: process.env.NEXT_PUBLIC_API_HOST_ADS,
        PIXEL_HOST: process.env.NEXT_PUBLIC_PIXEL_HOST,
        PIXEL_NABOO_HOST: process.env.NEXT_PUBLIC_PIXEL_NABOO_HOST,
        PRODUCT: process.env.NEXT_PUBLIC_PRODUCT,
        TRACK_VERSION: process.env.NEXT_PUBLIC_TRACK_VERSION,
        RELEASE_VERSION: process.env.NEXT_PUBLIC_RELEASE_VERSION,
      }
    : {}

/**
 * Get Next's public env variable from `process.env`. Protects
 * agains `process` not being defined in the browser.
 */
export function getNextJsPublicEnv(key: string): string | undefined {
  return NEXTJS_ENV[key]
}

/**
 * Shortcut to determine if current runtime is Next.js.
 */
export function isNextJs(): boolean {
  return !!getNextJsPublicEnv('IS_NEXTJS')
}

export const defaultApiHost =
  getNextJsPublicEnv('API_HOST') ?? process.env.API_HOST ?? ''
