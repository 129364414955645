import { getBrowser, getOS } from './utils'

export const isIOS = getOS() === 'iOS'
export const isAndroid = getOS() === 'Android'
export const isMac = getOS() === 'MacOS'
export const isWindows = getOS() === 'Windows'
export const isLinux = getOS() === 'Linux'

export const isChrome = getBrowser() === 'Chrome'
export const isFirefox = getBrowser() === 'Firefox'
export const isSafari = getBrowser() === 'Safari'
export const isIE = getBrowser() === 'IE'
export const isOpera = getBrowser() === 'Opera'
export const isEdge = getBrowser() === 'Edge'
export const isWeChat = getBrowser() === 'WeChat'

export { getBrowser, getOS, isMobileWeb } from './utils'
