import createStore from 'redux-zero'
import { applyMiddleware } from 'redux-zero/middleware'
import { bindActions } from 'redux-zero/utils'

import { actions } from './actions'
import { initialState } from './state'

let middlewares: any = []
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { connect } = require('redux-zero/devtools')
  middlewares = connect ? applyMiddleware(connect(initialState)) : []
}

export const store = createStore(initialState, middlewares)

export const dispatch = bindActions(actions as any, store)
