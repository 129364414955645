import type { ILiveStreamGlobalStateSlice } from './slice'
import { LIVESTREAM_SLICE_KEY } from './slice'
import type { IChannelUsername } from './state'

/**
 *
 */
export function heartbeatIntervalSelector(state: ILiveStreamGlobalStateSlice) {
  return state[LIVESTREAM_SLICE_KEY].heartbeatInterval
}

/**
 *
 */
export function joinedTopicsSelector(state: ILiveStreamGlobalStateSlice) {
  return state[LIVESTREAM_SLICE_KEY].joinedTopics
}

/**
 * @returns livestream model actions
 */
export function liveStreamActionsSelector(state: ILiveStreamGlobalStateSlice) {
  return state[LIVESTREAM_SLICE_KEY].actions
}

/**
 * @returns An active invitation token if it matches the video Id and has not
 *      been dismissed. Undefined otherwise.
 */
export function invitationTokenSelector(
  state: ILiveStreamGlobalStateSlice,
  liveStreamId?: string,
): string | undefined {
  if (!liveStreamId) {
    return
  }
  const { invitations, dismissedInvitations } = state[LIVESTREAM_SLICE_KEY]
  const invitationToken = invitations[liveStreamId]
  if (
    invitationToken &&
    !dismissedInvitations[liveStreamId]?.[invitationToken]
  ) {
    return invitationToken
  }
}

const defaultUsername = {
  name: '',
  isUserDefined: false,
}
/**
 * @returns channelUsername object for a given channel id
 */
export function usernameSelector(
  state: ILiveStreamGlobalStateSlice,
): IChannelUsername {
  // CS-3000: livestream slice can be undefined for unknown reasons.
  return (
    state[LIVESTREAM_SLICE_KEY]?.usernameHydrated ??
    (state[LIVESTREAM_SLICE_KEY]?.username || defaultUsername)
  )
}

/**
 *
 * @returns boolean representing whether or not the username was hydrated via widget param
 */
export function hasHydratedUsernameSelector(
  state: ILiveStreamGlobalStateSlice,
) {
  return !!state[LIVESTREAM_SLICE_KEY]?.usernameHydrated
}

/**
 * @returns "active" user state for a given livestream id
 */
export function activeWatchSelector(
  state: ILiveStreamGlobalStateSlice,
  liveStreamId: string | undefined,
): boolean {
  if (!liveStreamId) {
    return false
  }
  // CS-3000: livestream slice can be undefined for unknown reasons.
  return Boolean(state[LIVESTREAM_SLICE_KEY]?.activeWatches[liveStreamId])
}

/**
 * Selector to get whether or not current user has entered a livestream's giveaway
 */
export function liveStreamGiveawaySelector(
  state: ILiveStreamGlobalStateSlice,
  liveStreamId: string | undefined,
  interactionId: string | undefined,
) {
  if (!(liveStreamId && interactionId)) {
    return null
  }
  return state[LIVESTREAM_SLICE_KEY].liveStreamGiveaways[liveStreamId]?.[
    interactionId
  ]
}
