import type {
  ISharedGlobalState,
  PersistedKey,
} from '@core/store/sharedState/state'

/**
 * @returns The shared state actions.
 */
export function sharedStateActionsSelector(state: ISharedGlobalState) {
  return state.actions
}

/**
 * @returns The persisted data value.
 */
export function persistedDataSelector(
  state: ISharedGlobalState,
  key: PersistedKey,
): string | undefined {
  return state.persistedData[key]
}

/**
 * @returns The current page load id.
 */
export function pageLoadIdSelector(state: ISharedGlobalState) {
  return state.pageLoadId
}
