import type { Api_Video, IAPI_Channel } from '@core/api'
import type { Api_Page } from '@microsites/types/page'

export interface IMicrositePageProps {
  isCustomDomain: boolean
  isSSR: boolean
  ssrChannel?: IAPI_Channel['channel']
  ssrPage?: Api_Page
  ssrVideo?: Api_Video
  videoId?: string
  videos?: Api_Video[]
  hostname?: string
}

export * from './block'
export * from './blockConfig'
export * from './blockContent'
export * from './page'
