import { lens } from '@dhmk/zustand-lens'

import type { Actions } from './actions'
import { createActions } from './actions'
import type { State } from './state'
import { createState } from './state'

export const SLICE_KEY = 'channel'
export interface Slice {
  [SLICE_KEY]: State & {
    actions: Actions
  }
}

/**
 * Creates a slice for channels in global store
 */
export function createSlice(): Slice {
  return {
    [SLICE_KEY]: lens((set) => ({
      ...createState(),
      actions: createActions(set),
    })),
  }
}
