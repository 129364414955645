import { getGlobalConfig } from '@core/globalConfig'
import { isTruthy } from '@core/helpers/boolean'

import type { StorageKey } from '../types'

/**
 * Base for all caches.
 */
export class Cache<K extends StorageKey = string> {
  #consent: boolean | undefined = undefined
  #keys?: Set<string>

  /**
   * Constructor method
   * @param storage
   * @param keys
   */
  constructor(keys?: string[]) {
    this.#keys = keys ? new Set(keys) : undefined
  }

  /**
   * Test for consent of the storage instance*
   * @returns
   */
  isConsentGiven() {
    // Opt-in behavior
    if (isTruthy(getGlobalConfig().cookies_opt_in)) {
      return Boolean(this.#consent)
    }

    // Default behavior
    switch (this.#consent) {
      case false:
        return false
      case true:
      case undefined:
      default:
        return true
    }
  }

  /**
   * Grant consent for the storage instance
   */
  grantConsent() {
    this.#consent = true
  }

  /**
   * Revoke consent for the storage instance
   */
  revokeConsent() {
    this.#consent = false
  }

  /**
   * Validate if key belongs to the storage
   * @param key
   * @returns
   */
  validateKey(key: K) {
    return this.#keys ? this.#keys.has(key) : true
  }

  /**
   * Populate the cache with the current browser cookies/storage.
   */
  populate(_opts: { sweep?: boolean }): void {
    throw new Error('Method not implemented.')
  }

  /**
   * Flush the cached items to the browser cookies/storage.
   */
  flush(): void {
    throw new Error('Method not implemented.')
  }

  /**
   * Clear the storage for test purposes. Skip consent checks.
   */
  clearForTest(): void {
    throw new Error('Method not implemented.')
  }
}
