import type { EqualityChecker, UseBoundStore } from 'zustand'
import createStoreHook from 'zustand'

import { getGlobalStore } from './globalStore'
import type { IGlobalState } from './state'

export type IGlobalEqualityChecker = EqualityChecker<IGlobalState>

/**
 * CS-1330: We cannot share hook across binaries. This can cause preact runtime
 * issues if the customer websites load two different Firework scripts.
 */
let useGlobalStore: UseBoundStore<IGlobalState> | undefined

/**
 *
 */
export const resetUseGlobalStore = () => {
  useGlobalStore = undefined
}

/**
 *
 */
export const getGlobalStoreHook = () => {
  if (!useGlobalStore) {
    useGlobalStore = createStoreHook(getGlobalStore())
  }
  return useGlobalStore as UseBoundStore<IGlobalState>
}
