import type { PropsWithChildren } from 'react'
import React from 'react'

import type { SerializedStyles } from '@emotion/react'
import { css } from '@emotion/react'

export const BREAKPOINTS = ['576px', '768px', '992px', '1200px']

/**
 * Build a SerializedStyles class declaration for given property with with breakpoints
 * and respective values from array.
 * @param prop
 * @param values
 * @returns
 */
export const propWithBreakpoints = (
  prop: string,
  values: Array<string | number>,
) => {
  return css`
    ${prop}: ${values[0]};

    ${values[1] !== undefined &&
    css`
      @media (min-width: ${BREAKPOINTS[0]}) {
        ${prop}: ${values[1]};
      }
    `}

    ${values[2] !== undefined &&
    css`
      @media (min-width: ${BREAKPOINTS[1]}) {
        ${prop}: ${values[2]};
      }
    `}

    ${values[3] !== undefined &&
    css`
      @media (min-width: ${BREAKPOINTS[2]}) {
        ${prop}: ${values[3]};
      }
    `}
    
    ${values[4] !== undefined &&
    css`
      @media (min-width: ${BREAKPOINTS[3]}) {
        ${prop}: ${values[4]};
      }
    `}
  `
}

type MediaComponentProps = PropsWithChildren<{ css?: Array<SerializedStyles> }>

/**
 *
 */
export const XSmallAndBelow: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const smBreakpoint = BREAKPOINTS[0]

  return (
    <div
      css={[
        css`
          @media (min-width: ${smBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const SmallOnly: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const smBreakpoint = BREAKPOINTS[0]
  const mdBreakpoint = BREAKPOINTS[1]

  return (
    <div
      css={[
        css`
          @media (max-width: ${smBreakpoint}) {
            display: none !important;
          }

          @media (min-width: ${mdBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const SmallAndAbove: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const smBreakpoint = BREAKPOINTS[0]

  return (
    <div
      css={[
        css`
          @media (max-width: ${smBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const MediumAndBelow: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const lgBreakpoint = BREAKPOINTS[2]

  return (
    <div
      css={[
        css`
          @media (min-width: ${lgBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const LargeOnly: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const lgBreakpoint = BREAKPOINTS[2]
  const xlBreakpoint = BREAKPOINTS[3]

  return (
    <div
      css={[
        css`
          @media (max-width: ${lgBreakpoint}) {
            display: none !important;
          }

          @media (min-width: ${xlBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const LargeAndBelow: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const xlBreakpoint = BREAKPOINTS[3]

  return (
    <div
      css={[
        css`
          @media (min-width: ${xlBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const LargeAndAbove: React.FC<MediaComponentProps> = ({
  children,
  css: extendingCss,
  ...props
}) => {
  const lgBreakpoint = BREAKPOINTS[2]

  return (
    <div
      css={[
        css`
          @media (max-width: ${lgBreakpoint}) {
            display: none !important;
          }
        `,
        ...(extendingCss || []),
      ]}
      {...props}
    >
      {children}
    </div>
  )
}
