import type { NamedSet } from 'zustand/middleware'

import type { Channel } from '../types'

import type { State } from './state'

export interface Actions {
  updateChannel: (channel: Channel) => void
}

/**
 * Channels actions
 */
export function createActions(set: NamedSet<State>) {
  return {
    updateChannel: (channel: Channel) =>
      set(
        (state) => ({
          byId: {
            ...state.byId,
            [channel.id ?? channel.encoded_id]: channel,
          },
        }),
        false,
        'updateChannel',
      ),
  }
}
