import { pruneNilValues } from '@core/object'
import { getGuestId } from '@core/tracking'

/**
 * Maps guest id for tracking events
 */
export const prepareGuestFields = () => {
  const guestId = getGuestId()
  return pruneNilValues({
    guest_id: guestId,
    visitor_id: guestId,
  })
}
