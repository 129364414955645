import { useCallback, useState } from 'react'

import type { StorageKeyEssentialEnum } from '@core/storage/types'
import { getCookie, setCookie } from '@microsites/utils/cookies'

interface IOptions {
  expirationDays?: number
}

/**
 *
 */
export function useCookieStorage(
  name: StorageKeyEssentialEnum,
  defaultValue?: string,
): [string | null | undefined, (value: string, options?: IOptions) => void] {
  const [value, setValue] = useState(() => {
    try {
      return getCookie(name) || defaultValue
    } catch (_) {}
    return defaultValue
  })

  const setter = useCallback(
    (value: string, options: IOptions = {}) => {
      const { expirationDays } = options
      try {
        setValue(value)
        if (expirationDays) {
          const expireDate = new Date()
          expireDate.setDate(expireDate.getDate() + expirationDays)
          setCookie(name, value, expireDate)
        } else {
          setCookie(name, value)
        }
      } catch (_) {}
    },
    [name],
  )

  return [value, setter]
}
