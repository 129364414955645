import type { NamedSet } from 'zustand/middleware'

import type {
  ICheckedVideoIds,
  IGatedVideoState,
  IGateUnlockVideoIds,
} from './state'

export interface IGatedVideoActions {
  setUnlockedVideoIds: (unlockedVideoIds: IGateUnlockVideoIds) => void
  setCheckedVideoIds: (checkedVideoIds: ICheckedVideoIds) => void
}
/**
 * Gated Video actions
 */
export function createGatedVideoActions(
  set: NamedSet<IGatedVideoState>,
): IGatedVideoActions {
  return {
    setUnlockedVideoIds: (unlockedVideoIds) =>
      set({ unlockedVideoIds }, false, 'setUnlockedVideoIds'),
    setCheckedVideoIds: (checkedVideoIds) =>
      set({ checkedVideoIds }, false, 'setCheckedVideoIds'),
  }
}
