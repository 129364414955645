import { logError } from '@core/logging'
import { removeCookie } from '@core/storage'
import { removeItem } from '@core/storage/localStorage'

/**
 * Remove cookie from current domain and all subdomains including root domain.
 */
export function removeCookieAndFallback(
  key: string,
  domain: string = window?.location?.hostname,
) {
  try {
    // Remove the key of the current domain name
    removeCookie(key)

    // AVA-2414 - special edge case for key `fw_se` when domain is not given (e.g. in dynamic iframe)
    // When this key wasn't able to be cleared (because hostname didn't match), it would cause infinite reloading issues
    let d = domain
    if (key === 'fw_se' && !domain) {
      d = window?.parent?.location?.hostname
    }

    // Remove from all subdomains including root domain
    const domainSplitArr = d.split('.')
    for (let i = 1; i < domainSplitArr.length; i++) {
      const domainToTry = domainSplitArr.slice((i + 1) * -1).join('.')
      try {
        removeCookie(key, { domain: domainToTry })
      } catch (err) {}
    }

    removeItem(key)
  } catch (error) {
    logError(error)
  }
}
