/**
 * Old browsers may not support navigator.cookieEnabled.
 * This method provides a fallback method for checking of cookies are enabled.
 * SO mentions alternate methods as well here:
 * https://stackoverflow.com/questions/4603289/how-to-detect-that-javascript-and-or-cookies-are-disabled
 */
function testBrowserCookies(docDI: Document): boolean {
  if (typeof document === 'undefined') {
    return false
  }

  // Quick test if browser has cookieEnabled host property
  if (navigator.cookieEnabled) {
    return true
  }
  // Create cookie
  docDI.cookie = 'cookietest=1'
  const ret = docDI.cookie.indexOf('cookietest=') !== -1
  // Delete cookie
  docDI.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
  return ret
}

/**
 * Whether browser allows cookies to be stored (tested by storing a cookie).
 */
export const doesBrowserAllowCookies =
  typeof document !== 'undefined' ? testBrowserCookies(document) : false
