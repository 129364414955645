export interface ILiveStreamState {
  heartbeatInterval?: ReturnType<typeof setInterval>
  joinedTopics: string[]
  dismissedInvitations: Record<string, Record<string, true>>
  invitations: Record<string, string>
  username: IChannelUsername
  usernameHydrated?: IChannelUsername
  activeWatches: Record<string, boolean>
  liveStreamGiveaways: Record<
    string,
    Record<
      string,
      { entered: boolean; winnerRevealed?: boolean; dismissed?: boolean }
    >
  >
}

export interface IChannelUsername {
  name: string
  isUserDefined: boolean
}

/**
 * Global state for live stream
 */
export function createLiveStreamState(): ILiveStreamState {
  return {
    heartbeatInterval: undefined,
    joinedTopics: [],
    dismissedInvitations: {},
    invitations: {},
    username: { name: '', isUserDefined: false },
    activeWatches: {},
    liveStreamGiveaways: {},
  }
}
