/**
 * Custom tracking provides SDK access to call custom events
 * like purchase, signup, etc. by providing any event name and
 * extra payload (total price, customer country, tc.).
 *
 * Events will be collected by Firework's analytics infrastructure
 * and can be displayed in business portal.
 *
 * Payload for custom events is enhanced by last consumed channel, video and livestream
 * to allow use at any time (e.g. Client wants to track `purchase` after days of actual content
 * consumption and attribute them to the last consumed channel, video and livestream).
 */

import {
  EnumFunnelTrackingCookies,
  getCookieWithFallback,
} from '@core/tracking/funnelTracking'

/** Last visited channel, video, livestream */

/**
 *
 */
export function getLastEngagedTimestamp(): string | undefined {
  return getCookieWithFallback(
    EnumFunnelTrackingCookies.FW_LAST_ENGAGEMENT_TIMESTAMP,
  )?.value
}
/**
 *
 */
export function getLastChannelId(): string | undefined {
  return getCookieWithFallback(EnumFunnelTrackingCookies.FW_CHANNEL_ID)?.value
}

/**
 *
 */
export function getLastVideoId(): string | undefined {
  return getCookieWithFallback(EnumFunnelTrackingCookies.FW_LAST_VIDEO_ID)
    ?.value
}

/**
 * @returns Last business membership id cookie item persisted in storage.
 */
export function getLastBusinessMembershipIdCookie() {
  return getCookieWithFallback(
    EnumFunnelTrackingCookies.FW_BUSINESS_MEMBERSHIP_ID,
  )
}

/**
 * @returns Last chat channel id persisted in storage
 */
export function getLastChatChannelId() {
  return getCookieWithFallback(EnumFunnelTrackingCookies.FW_CHAT_CHANNEL_ID)
    ?.value
}

/**
 * @returns Last showroom id cookie item persisted in storage.
 */
export function getLastShowroomIdCookie() {
  return getCookieWithFallback(EnumFunnelTrackingCookies.FW_SHOWROOM_ID)
}

/**
 * @returns Last assistance id for an assistance that has played an answer video persisted in storage
 */
export function getLastAssistanceIdWithAnswer(): string | undefined {
  return getCookieWithFallback(
    EnumFunnelTrackingCookies.FW_ASSISTANCE_ID_WITH_ANSWER,
  )?.value
}

/**
 * @returns Last timestamp that an AVA answer video has started playing persisted in storage
 */
export function getLastAvaEngagedWithAnswerTimestamp(): string | undefined {
  return getCookieWithFallback(
    EnumFunnelTrackingCookies.FW_LAST_AVA_ENGAGEMENT_WITH_ANSWER_TIMESTAMP,
  )?.value
}
