import Cookies from 'js-cookie'

import { Cache } from './Cache'

type CookiesSetParams<K> = [
  name: K,
  value: Parameters<typeof Cookies.set>[1],
  options?: Parameters<typeof Cookies.set>[2],
]
type CookiesRemoveParams<K> = [
  name: K,
  options?: Parameters<typeof Cookies.remove>[1],
]

/**
 * Cookie cache module class. Stores cookie request until
 * consent is given.
 */
export class CookiesCache<K extends string = string> extends Cache<K> {
  #itemsToReplay: Array<
    ['set', CookiesSetParams<K>] | ['remove', CookiesRemoveParams<K>]
  >
  #itemsValues: Map<CookiesSetParams<K>[0], CookiesSetParams<K>[1]>

  /**
   * Constructor by the way.
   */
  constructor(keys?: string[]) {
    super(keys)

    this.#itemsToReplay = []
    this.#itemsValues = new Map()

    this.populate()
  }

  /**
   * Add item into cache storage.
   * @param item
   */
  set(...args: CookiesSetParams<K>) {
    if (this.isConsentGiven()) {
      Cookies.set(...args)
    } else {
      this.#itemsToReplay.push(['set', args])
      this.#itemsValues.set(args[0], args[1])
    }
  }

  /**
   * Get value of cached item.
   * @param args
   * @returns
   */
  get(name: K): string | undefined {
    if (this.isConsentGiven()) {
      return Cookies.get(name)
    } else {
      return this.#itemsValues.get(name)
    }
  }

  /**
   * Remove and item from cache storage.
   * @param name
   */
  remove(...args: CookiesRemoveParams<K>) {
    if (this.isConsentGiven()) {
      Cookies.remove(...args)
    } else {
      this.#itemsValues.delete(args[0])
      this.#itemsToReplay.push(['remove', args])
    }
  }

  /**
   * Populate the cache with the current browser cookies/storage. Sweeps
   * the storage if needed.
   */
  override populate(opts: { sweep?: boolean } = {}) {
    Object.entries(Cookies.get() || {})
      .filter(([key]) => this.validateKey(key as K))
      .forEach(([key, value]) => {
        this.#itemsValues.set(key as K, value)
        if (opts.sweep) {
          try {
            Cookies.remove(key, { domain: location.host })
          } catch (e) {
            // ignore
          }
        }
      })
  }

  /**
   * Flush the cached items to the browser cookies/storage.
   */
  override flush() {
    this.#itemsToReplay.forEach(([fnName, fnArgs]) => {
      switch (fnName) {
        case 'set':
          Cookies.set(...fnArgs)
          break
        case 'remove':
          Cookies.remove(...fnArgs)
          break
      }
    })
    this.#itemsToReplay = []
  }

  /**
   * Clear the cache for testing purposes.
   */
  override clearForTest() {
    this.#itemsToReplay = []
    this.#itemsValues.clear()
  }
}
