import Cookies from 'js-cookie'

import { logError } from '@core/logging'
import { setCookie } from '@core/storage'
import { removeItem } from '@core/storage/localStorage'

/**
 * Attempts to set the cookie on the root domain first, then expands to subdomains when unsuccessful.
 * Ie. Given a domain of `live.example.co.uk`, this method will attempt to set the cookie
 * on `co.uk` first, then `example.co.uk`, then `live.example.co.uk`.
 *
 * Note: We need to use `js-cookie` here instead of `@core/storage` so we can set the cookie
 * directly (regardless the cookie consent) to read it afterwards to make sure the browser
 * did not rejected it - thats how we test how far to the root domain we can set it for (see the example above).
 */
export function setCookieWithRootDomain(
  name: string,
  value: string,
  opts: {
    domain?: string
    expirationDate?: Date
  },
): string | void {
  const { expirationDate, domain = '' } = opts

  try {
    if (!domain) {
      return setCookie(name, value, opts)
    }

    // Removing cookie first to ensure that as we try to set the cookie on different domains,
    // we do not pull an OLD cookie that was previously created with the same name,
    // but for a different domain
    try {
      // See note about usage of `js-cookie`.
      Cookies.remove(name, { domain })
    } catch (err) {
      // Pass silently
    }
    // Removing the cookie from local storage
    removeItem(name)

    // Iterate over domain parts by prepending them while trying
    // to set the cookie on each of them. Success is tested by reading the cookie
    // afterwards.
    const domainSplitArr = domain.split('.')

    for (let i = 1; i < domainSplitArr.length; i++) {
      const domainToTry = domainSplitArr.slice((i + 1) * -1).join('.')

      // See note about usage of `js-cookie`.
      try {
        Cookies.set(name, value, {
          domain: domainToTry,
          expires: expirationDate,
        })
      } catch (err) {
        // Pass silently
      }

      // If a cookie is set successfully on the current domain,
      // there is no need to move to the next subdomain.
      let cookieCreated = false
      try {
        cookieCreated = !!Cookies.get(name)
      } catch (err) {
        // Pass silently
      }
      // Remove the cookie in case the consent was not yet given.
      // See note about usage of `js-cookie`.
      try {
        Cookies.remove(name, {
          domain: domainToTry,
          expires: expirationDate,
        })
      } catch (err) {
        // Pass silently
      }

      if (cookieCreated) {
        // Do a proper setCookie call in case the consent was not yet given
        // so the cookie creation can be replayed after the consent is given
        setCookie(name, value, {
          domain: domainToTry,
          expirationDate,
        })
        break
      }
    }
  } catch (err) {
    logError(err)
  }
}
