import deepmerge from 'deepmerge'

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P]
}

/**
 *
 * @param args
 * @returns
 */
function merge<T extends object>(...args: Array<RecursivePartial<T>>): T {
  return deepmerge.all(args) as T
}

export default merge
