import { devtools } from 'zustand/middleware'

import type { StateCreator } from './state'

/**
 * Adds Redux devtools.
 * https://github.com/pmndrs/zustand#redux-devtools
 */
export function addDevtool(creator: StateCreator): StateCreator {
  return devtools(creator, {
    name: 'FWN Global Store',
  }) as StateCreator
}
