import {
  EnumFunnelTrackingCookies,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'

/**
 *
 */
export function persistVideoId(encodedVideoId: string) {
  setCookieWithFallback(
    EnumFunnelTrackingCookies.FW_LAST_VIDEO_ID,
    encodedVideoId,
  )
}
