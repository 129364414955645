/**
 * Compares two objects to determine if they are equivalent.
 * @param x
 * @param y
 * @returns
 */
function isEqual(x: unknown, y: unknown): boolean {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => isEqual(x[key], y[key]))
    : x === y
}

export default isEqual
