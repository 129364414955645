import { lens } from '@dhmk/zustand-lens'

import type { IGatedVideoActions } from './actions'
import { createGatedVideoActions } from './actions'
import type { IGatedVideoState } from './state'
import { createGatedVideoState } from './state'

export const GATED_VIDEO_SLICE_KEY = 'gatedVideo'
export interface IGatedVideoStateSlice {
  [GATED_VIDEO_SLICE_KEY]: IGatedVideoState & {
    actions: IGatedVideoActions
  }
}
/**
 * Creates a slice of the global state for gated video.
 */
export function createGatedVideoSlice(): IGatedVideoStateSlice {
  return {
    [GATED_VIDEO_SLICE_KEY]: lens((set) => ({
      ...createGatedVideoState(),
      actions: createGatedVideoActions(set),
    })),
  }
}
