import type { Channel } from '../types'

export interface State {
  byId: Record<Channel['encoded_id'], Channel>
}

/**
 * Global state for channels
 */
export function createState(): State {
  return {
    byId: {},
  }
}
