import {
  EnumFunnelTrackingCookies,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'

/**
 * Persist last channel id
 */
export function persistChannelId(encodedChannelId: string) {
  setCookieWithFallback(
    EnumFunnelTrackingCookies.FW_CHANNEL_ID,
    encodedChannelId,
  )
}
