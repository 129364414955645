import { getLanguage } from '@core/params/helpers/getLanguage'
import type { IAllWidgetParams } from '@core/types'
import {
  PlayerTitleAlignmentEnum,
  ProductTagPlacement,
  ResetTransformEnum,
  ShareEnum,
  ShoppingCartStyle,
  WidgetSizeEnum,
} from '@core/types'
import { getOS } from '@core/userAgent'
import { DEFAULT_PER_PAGE } from '@core/widgets/common'
import type { AnyWidgetAppContext } from '@embed/hooks/useEmbedAppContext'

export interface ICommonState {
  // @TODO for now there is limitation of type not including PWA type here
  appContext: AnyWidgetAppContext | null
  engagement: {
    seconds_watched?: number
    os?: string
    app_version?: string
    platform?: string
    product?: string
  }
  defaultParams: IAllWidgetParams
}

export const initialState: ICommonState = {
  appContext: null,
  engagement: {
    // :nav, // "first", "next", "prev", "push", "pop"
    // :context,
    // :video_type,
    seconds_watched: 0,
    os: getOS(),
    app_version: process.env.RELEASE_VERSION,
    platform: process.env.PLATFORM,
    product: process.env.PRODUCT,
  },
  defaultParams: {
    a11y_disable_focus: false,
    a11y_disable_previews: false,
    ad_positions: '',
    ads_hide_badge: false,
    allow_duplicate_ads: false,
    api_host: process.env.API_HOST || '',
    api_host_ads: process.env.API_HOST_ADS || '',
    api_host_tracking: process.env.PIXEL_HOST || '',
    api_host_tracking_legacy: process.env.PIXEL_NABOO_HOST || '',
    auto_apply_coupon_enabled: true,
    autoplay: true,
    auto_subtitles_enabled: true,
    autoplay_keep_thumbnail: false,
    branding: true,
    captions: true,
    dir: '',
    email_embed: true,
    expand_prod_desc_enabled: true,
    full_height_pdp: false,
    fullscreen_pdp_enabled: false,
    handle_product_card_click: 'internal',
    handle_product_cta_click: 'internal',
    hero_flip_order: false,
    hero_hide_countdown: false,
    hero_previous_event_promotion: true,
    hero_text_mode: 'even',
    hide_product_price: true, // [GROW-631] This feature is being GA'd with default to true
    in_sdk_webview:
      typeof window !== 'undefined' &&
      window.origin?.includes('fw-sdk-webview'),
    key_moments: true, // GA Starting Mar 2 2023
    lang: getLanguage(),
    light_mode_pdp: false,
    livestream_allow_username_change: true,
    livestream_chat: true,
    livestream_prompt_username_change: null,
    loop: true,
    lsdesktop: true,
    minimized: false,
    muted: false, // [QA-1191] Whether to unmute on user interaction (fullscreen, thumbnail...). Note: Player always un-mutes on direct user interaction with mute button.
    per_page: DEFAULT_PER_PAGE,
    persist_cart: false,
    pip: false,
    pip_navigate: true,
    pip_observe: false,
    player_add_to_calendar: true,
    player_captions: true,
    player_close: true,
    player_detached: false,
    player_fullscreen: true,
    player_margin: undefined, // set by CSS var
    player_minimize: true,
    player_more_menu: true,
    player_placement: undefined, // set by CSS var
    player_play_pause: false,
    player_tap_to_unmute: true,
    player_tiles: true,
    player_unmute: true,
    priority: 'skus,playlist,hashtag,playlist_collection',
    product_card: true,
    product_checkout_card_v2: true,
    product_click_use_instream_pdp: true,
    product_image_carousel: false,
    product_index: false,
    product_tag_placement: ProductTagPlacement.TOP,
    microsites_host: process.env.MICROSITES_HOST || '',
    qr_code: true,
    reset_transform: ResetTransformEnum.FULLSCREEN,
    share: ShareEnum.HASH,
    shopping_cart_style: ShoppingCartStyle.CART,
    show_ads: true,
    show_preroll: true,
    size: WidgetSizeEnum.LARGE,
    skus: '',
    tap_to_watch: true,
    theme: '',
    thumbnail_style: 'video',
    thumbnail_cta: false,
    thumbnail_product_card: false,
    thumbnail_product_card_bottom: false,
    title_alignment: PlayerTitleAlignmentEnum.LEFT,
    title_size: 28,
    ui_border_style: 'default',
    ui_button_color: '',
    ui_button_font_color: '',
    ui_font_css: '',
    ui_full_cta: true,
    ui_primary_color: '',
    ui_share_sheet: 'facebook,twitter,whatsapp,sms,copy,native',
    ui_player_style: 'classic',
    wait_for_vast_attrs: false,
    vast_xml: '',
  },
}
