import type { StorageKey } from '../types'

import { Cache } from './Cache'

/**
 * StorageCache wrapper for Web Storage API of localStorage/sessionStorage which implements
 * interim consent cache and flush mechanism. Can be used as memory only storage.
 *
 * Note: No `storage` event should be dispatched
 * as it will cause recursion loop in the code. Dispatching
 * such event on window wont propagate to the other tabs anyway.
 */
export class StorageMemoryCache<K extends StorageKey = string>
  extends Cache<K>
  implements Storage
{
  #data: Map<K, string | null>
  #storage?: Storage
  #isStorageAccessible = true

  /**
   * Constructor
   * @param storage
   * @param keys
   */
  constructor(keys?: string[], storage?: Storage) {
    // console.warn('StorageMemoryCache instance created')
    super(keys)
    this.#storage = storage
    this.#data = new Map()
    this.populate()
  }

  /**
   * Provides the number of key/value pairs currently present in the list
   */
  get length() {
    if (this.#storage && this.shouldUseStorage()) {
      return [...Array(this.#storage.length).keys()].filter((_, i) =>
        this.validateKey(this.#storage?.key(i) as K),
      ).length
    } else {
      return this.#data.size
    }
  }

  /**
   * Returns stored key
   * @param key
   * @returns
   */
  getItem(key: K) {
    if (!this.validateKey(key)) {
      return null
    }
    if (this.#storage && this.shouldUseStorage()) {
      return this.#storage?.getItem(key)
    }
    return this.#data.get(key) ?? null
  }

  /**
   * Sets key to value
   * @param key
   * @param value
   */
  setItem(key: K, value: string) {
    if (!this.validateKey(key)) {
      return
    }
    if (this.#storage && this.shouldUseStorage()) {
      try {
        this.#storage.setItem(key, value)
      } catch (e) {
        this.#isStorageAccessible = false
        this.#data.set(key, value)
      }
    } else {
      this.#data.set(key, value)
    }
  }

  /**
   * Removes item from storage
   * @param key
   */
  removeItem(key: K) {
    if (!this.validateKey(key)) {
      return
    }
    if (this.#storage && this.shouldUseStorage()) {
      this.#storage.removeItem(key)
    }
    this.#data.delete(key)
  }

  /**
   * Removes all key/value pairs, if there are any.
   */
  clear() {
    if (this.#storage && this.shouldUseStorage()) {
      for (let i = 0; i < this.#storage.length; i++) {
        const key = this.#storage.key(i) as K
        if (this.validateKey(key)) {
          this.#storage.removeItem(key)
        }
      }
    }
    this.#data.clear()
  }

  /**
   * Returns the name of the nth key, or null if n is greater than or equal to the number of key/value pairs.
   * Note: Its not possible to implement this method as all keys might be scattered between multiple caches.
   * @param _index
   */
  key(_index: number): K | null {
    throw new Error('Method not implemented.')
  }

  /**
   * Test storage instance availability and consent
   * @returns
   */
  shouldUseStorage() {
    return (
      Boolean(this.#storage) &&
      this.#isStorageAccessible &&
      this.isConsentGiven()
    )
  }

  /**
   * Load the storage content into memory interim cache. Sweeps
   * the storage if needed (e.g.: Consent is removed).
   */
  override populate(opts: { sweep?: boolean } = {}) {
    try {
      if (this.#storage) {
        const length = this.#storage.length

        for (let i = 0; i < length; i++) {
          const key = this.#storage.key(i) as K
          if (this.validateKey(key)) {
            this.#data.set(key, this.#storage.getItem(key))
          }
        }

        if (opts.sweep && length && length > 0) {
          const keys = new Array(length)
            .fill(undefined)
            .map((_, i) => this.#storage?.key(i) as K)
            .filter((key) => key && this.validateKey(key))

          keys.forEach((key) => {
            this.#storage?.removeItem(key)
          })
        }
      }
    } catch (e) {}
  }

  /**
   * Flush the interim consent cache to the localStorage/sessionStorage instance.
   */
  override flush() {
    if (this.#storage) {
      try {
        this.#data.forEach((value, key) => {
          if (value) {
            this.#storage?.setItem(key, value)
          }
        })
        this.#data.clear()
      } catch (e) {
        this.#isStorageAccessible = false
      }
    }
  }

  /**
   * Clear the storage for test purposes. Skip consent checks.
   */
  override clearForTest() {
    this.#storage?.clear()
    this.#data.clear()
  }
}
