import { lens } from '@dhmk/zustand-lens'

import type { ILiveStreamAction } from './actions'
import { createLiveStreamActions } from './actions'
import type { ILiveStreamState } from './state'
import { createLiveStreamState } from './state'

export const LIVESTREAM_SLICE_KEY = 'liveStream'

export interface ILiveStreamGlobalStateSlice {
  [LIVESTREAM_SLICE_KEY]: ILiveStreamState & {
    actions: ILiveStreamAction
  }
}

/**
 *
 */
export function createLiveStreamSlice(): ILiveStreamGlobalStateSlice {
  return {
    [LIVESTREAM_SLICE_KEY]: lens((set) => ({
      ...createLiveStreamState(),
      actions: createLiveStreamActions(set),
    })),
  }
}
