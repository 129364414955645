/**
 * @fileoverview State to be shared by other slices and represents the state of
 * the application.
 */
import type { NamedSet } from 'zustand/middleware'

import { uuidv4 } from '@embed/helpers'

/**
 * Possible keys that can be persisted. New features should register their key
 * here. This is needed to ensure that keys are unique across the app.
 */
export type PersistedKey =
  | 'lastFocusedTab'
  | 'liveHelperUiContext'
  | 'messagePreview'
  | 'lastEngagedConversation'
  | 'features'
  | 'avaTreasureBox'

export interface ISharedGlobalState {
  actions: {
    /** Sets the persisted data value by key. */
    setPersistedData: (key: PersistedKey, value: string) => void
  }
  /**
   * A UUID representing the page. It is used for grouping tracking events
   * to a single page. Also used to generate Agora UID for the page.
   * The page load ID is different from the guest ID. The guest ID is stored in
   * the local storage and is used across pages.
   */
  pageLoadId: string

  /**
   * Data that is persisted across page loads.
   */
  persistedData: Record<string, string>
}

/**
 * Shared global state
 */
export function createSharedGlobalState(
  set: NamedSet<ISharedGlobalState>,
): ISharedGlobalState {
  return {
    actions: {
      setPersistedData: (key: PersistedKey, value: string) => {
        set((state) => {
          return {
            ...state,
            persistedData: {
              ...state.persistedData,
              [key]: value,
            },
          }
        })
      },
    },
    pageLoadId: uuidv4(),
    persistedData: {},
  }
}
