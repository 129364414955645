import { logError } from '@core/logging'
import { getCookieWithFallback } from '@core/tracking/funnelTracking'

import { FWREFERRER_KEY } from './constant'
import type { persistFWReferrer } from './persistFWReferrer'

type FWReferrer = ReturnType<typeof persistFWReferrer>

/**
 * Read `fwreferrer` values from store (localStorage) and compose an object
 * which is to be added to each tracking request data.
 */
export function getFWReferrerForTracking(): Partial<{
  _referrer_video_id: string
  _referrer_channel_id: string
  _referrer_product_id: string
  referrer_origin: string
  referrer_guest_id: string
  _referrer_business_store_id: string
}> {
  try {
    let fwreferrer = getCookieWithFallback(FWREFERRER_KEY)?.value as
      | string
      | FWReferrer
    if (typeof fwreferrer === 'string') {
      fwreferrer = JSON.parse(fwreferrer) as FWReferrer
    }

    return {
      _referrer_video_id: fwreferrer?.video_id ?? '',
      _referrer_channel_id: fwreferrer?.channel_id ?? '',
      _referrer_product_id: fwreferrer?.product_id ?? '',
      referrer_origin: fwreferrer?.origin ?? '',
      referrer_guest_id: fwreferrer?.guest_id ?? '',
      _referrer_business_store_id: fwreferrer?.business_store_id ?? '',
    }
  } catch (e) {
    logError(e)
    return {}
  }
}
