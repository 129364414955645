/**
 * @fileoverview
 *
 * Implementation of memoryStorage with API identical
 * to local(session)Storage by returning an instance of
 * `StorageMemoryCache` with no Storage instance passed in.
 */

import { StorageMemoryCache } from '@core/storage/classes/StorageMemoryCache'

export const memoryStorage = new StorageMemoryCache()
