/**
 * @fileoverview
 * Functions to send tracking via navigator.SendBeacon
 */

import { getGlobalConfig } from '@core/globalConfig'
import isEmpty from '@core/lodash/isEmpty'
import { logError } from '@core/logging'
import { safeLogVariable } from '@core/logging/helpers'
import { isPlainObject } from '@core/object/libs/pruneNilValues'
import { stringifyQueryParams } from '@core/window/libs/url'
import { getNextJsPublicEnv } from '@src/helpers'

const objectToFormData = (
  data: any,
  fd?: FormData,
  namespace?: string,
): FormData | null => {
  const formData = fd || new FormData()

  return data
    ? Object.keys(data).reduce((acc, key) => {
        if (isPlainObject(data[key])) {
          objectToFormData(data[key], formData, key)
        } else {
          const formDataKey = namespace ? `${namespace}[${key}]` : key
          acc.append(formDataKey, data[key])
        }
        return acc
      }, formData)
    : null
}

/**
 * There is a bug with Chrome not setting the correct
 * Content-Type: application/x-www-form-urlencoded.
 * Firefox and Safari are both working.
 * https://bugs.chromium.org/p/chromium/issues/detail?id=747787
 * Implemented a workaround by sending multipart/form-data.
 */

/**
 * snedBeacon wrapper for legacy tracking (Naboo)
 */
export const sendBeaconLegacy = ({
  path,
  query,
  data,
  host,
}: {
  path: string
  query: Record<string, unknown>
  data: Record<string, unknown>
  host: string | undefined
}): Promise<void | boolean> => {
  if (!host) {
    host =
      getGlobalConfig().api_host_tracking_legacy ??
      getNextJsPublicEnv('PIXEL_NABOO_HOST') ??
      process.env.PIXEL_NABOO_HOST ??
      ''
  }

  if (navigator.sendBeacon) {
    const q = query
      ? Object.entries(query)
          .filter(([, val]) => val !== undefined)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      : {}

    const formData = objectToFormData(data)

    const success = navigator.sendBeacon(
      `${host}${path}${stringifyQueryParams(q, { addQueryPrefix: true })}`,
      formData,
    )

    return Promise.resolve(success)
  }
  return Promise.reject()
}

/**
 * sendBeacon wrapper for pixel tracking
 */
export const sendBeaconPixel = ({
  path,
  data,
  host,
}: {
  path: string
  data: Record<string, string | number>
  host: string | undefined
}): Promise<void | boolean> => {
  if (!host) {
    host =
      getGlobalConfig().api_host_tracking ??
      getNextJsPublicEnv('PIXEL_HOST') ??
      process.env.PIXEL_HOST ??
      ''
  }

  if (navigator.sendBeacon) {
    try {
      const jsonData = JSON.stringify(data)
      if (!jsonData) {
        logError(new Error('Empty tracking data'), {
          data: safeLogVariable(data),
        })
      }

      // multipart/form-data
      const formData = new FormData()
      formData.append('_json', encodeURI(jsonData))

      if (isEmpty(formData.get('_json'))) {
        throw Error('Empty payload')
      }

      const url = `${host}${path}`
      const success = navigator.sendBeacon(url, formData)

      return Promise.resolve(success)
    } catch (err) {
      return Promise.reject()
    }
  }
  return Promise.reject()
}
