export interface IDeviceSettingsState {
  /** The camera device ID. */
  cameraId?: string

  /** The speaker device ID. */
  speakerId?: string

  /** The microphone device ID. */
  microphoneId?: string

  /** Whether we encountered an error when getting the camera ID. */
  failedToGetCameraId?: boolean

  /** Whether to use front or back camera (only applied to mobile device). */
  facingMode: 'user' | 'environment'
}

/**
 *
 */
export function createDeviceSettingsState(): IDeviceSettingsState {
  return {
    facingMode: 'user',
  }
}
