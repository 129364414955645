import {
  merge as channelMerge,
  partialize as channelPartialize,
} from '@core/channel/model/persist'
import {
  merge as deviceSettingsMerge,
  partialize as deviceSettingsPartialize,
} from '@core/deviceSettings'
import {
  merge as gatedVideoMerge,
  partialize as gatedVideoPartialize,
} from '@core/gatedVideo/model/persist'
import {
  merge as livestreamMerge,
  partializeForLocalStorage as livestreamPartializeForLocalStorage,
  partializeForSessionStorage as livestreamPartializeForSessionStorage,
} from '@core/livestream/model/persist'
import merge from '@core/lodash/merge'
import {
  merge as shoppingMerge,
  partialize as shoppingPartialize,
} from '@core/shopping/model'
import { getLocalStorage, getSessionStorage } from '@core/storage'
import { StorageKeyEssentialEnum } from '@core/storage/types'
import type { PersistOptions } from '@core/zustand/persist'
import { persist } from '@core/zustand/persist'

import {
  merge as sharedStateMerge,
  partialize as sharedStatePartialize,
} from './sharedState/persist'
import type { IGlobalState, StateCreator } from './state'

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export const persistOptions = {
  name: StorageKeyEssentialEnum.fw_gs,
  getStorage: () => ({
    /**
     * Fetches partial state from both storages and merges them. Result
     * will be processed by `merge` method.
     * @param key
     * @returns
     */
    getItem: (key: string) => {
      const value1 = JSON.parse(getLocalStorage().getItem(key) ?? '{}')
      const value2 = JSON.parse(getSessionStorage().getItem(key) ?? '{}')
      const value = merge({}, value1, value2)
      return JSON.stringify(value)
    },
    /**
     * Splits partial state into `local` and `session` keys and saves them
     * into corresponding storages.
     * @param key
     * @param value
     */
    setItem: (key: string, value: string) => {
      const {
        state: { local, session },
        version,
      } = JSON.parse(value)

      getLocalStorage().setItem(key, JSON.stringify({ state: local, version }))
      getSessionStorage().setItem(
        key,
        JSON.stringify({ state: session, version }),
      )
    },
    /**
     * Removes persist key from both storages.
     * @param key
     */
    removeItem: (key: string) => {
      getLocalStorage().removeItem(key)
      getSessionStorage().removeItem(key)
    },
  }),
  /**
   * Partialize will provide segmented state for each storage and will
   * be processed by `setItem` storage method.
   * @param state
   * @returns
   */
  partialize: (state: IGlobalState) =>
    ({
      local: {
        ...sharedStatePartialize(state),
        ...deviceSettingsPartialize(state),
        ...shoppingPartialize(state),
        ...livestreamPartializeForLocalStorage(state),
        ...gatedVideoPartialize(state),
      },
      session: {
        ...livestreamPartializeForSessionStorage(state),
        ...channelPartialize(state),
      },
    }) as {
      local: DeepPartial<IGlobalState>
      session: DeepPartial<IGlobalState>
    },
  /**
   * Merge persisted state with current state. Persisted state is provided by
   * `getItem` storage method.
   * @param persistedState
   * @param currentState
   * @returns
   */
  merge: (persistedState: IGlobalState, currentState: IGlobalState) => {
    return {
      ...currentState,
      ...sharedStateMerge(persistedState, currentState),
      ...deviceSettingsMerge(persistedState, currentState),
      ...shoppingMerge(persistedState, currentState),
      ...livestreamMerge(persistedState, currentState),
      ...gatedVideoMerge(persistedState, currentState),
      ...channelMerge(persistedState, currentState),
    }
  },
}

/**
 * Adds persist middleware. See our custom `persistOptions` for more details.
 * https://github.com/pmndrs/zustand#persist-middleware
 */
export function addPersist(creator: StateCreator): StateCreator {
  return persist(
    creator,
    persistOptions as PersistOptions<IGlobalState, Partial<IGlobalState>>,
  ) as StateCreator
}
