import type { NamedSet } from 'zustand/middleware/devtools'

import type { PlayerStateSync } from '@core/player/types'
import { PlayerLayout, VideoPlayerOpenReason } from '@core/player/types'

import type { PlayerGlobalState } from './types'

/**
 * Actions for shared player state
 */
export function createPlayerActions(set: NamedSet<PlayerGlobalState>) {
  return {
    setPlayerState: (widgetId: string, playerState: PlayerStateSync) => {
      set(
        (state) => {
          const isOpened = [
            PlayerLayout.FULLSCREEN,
            PlayerLayout.MINIMIZED,
          ].includes(playerState.layout)

          // Close all players if new entry is fullscreen/minimized
          const playerStates = isOpened
            ? Object.fromEntries(
                Object.entries(state.playerStates).map(
                  ([widgetId, playerState]) => [
                    widgetId,
                    {
                      ...playerState,
                      openReason: VideoPlayerOpenReason.CLOSE_ME,
                    },
                  ],
                ),
              )
            : state.playerStates

          return {
            playerStates: { ...playerStates, [widgetId]: playerState },
            playerOpenedWidgetId: isOpened
              ? playerState.widget_id
              : playerState.widget_id === state.playerOpenedWidgetId
                ? undefined
                : state.playerOpenedWidgetId,
          }
        },
        false,
        'playerStateSet',
      )
    },
    unsetPlayerState: (widgetId: string) => {
      set(
        (state) => {
          const { [widgetId]: _, ...playerStates } = state.playerStates || {}
          const playerOpenedWidgetId =
            widgetId === state.playerOpenedWidgetId &&
            [PlayerLayout.FULLSCREEN, PlayerLayout.MINIMIZED].includes(
              playerStates?.[widgetId]?.layout,
            )
              ? state.playerOpenedWidgetId
              : undefined
          return {
            playerStates,
            playerOpenedWidgetId,
          }
        },
        false,
        'playerStateUnset',
      )
    },

    /** Livestream relate global actions */
    setLivestreamJoinEventTracked: (videoId: string) => {
      set(
        (state) => {
          return {
            isLivestreamJoinEventTrackedByVideoId: {
              ...state.isLivestreamJoinEventTrackedByVideoId,
              [videoId]: true,
            },
          }
        },
        false,
        'setLivestreamJoinEventTracked',
      )
    },
    unsetLivestreamJoinEventTracked: (videoId: string) => {
      set(
        (state) => {
          return {
            isLivestreamJoinEventTrackedByVideoId: {
              ...state.isLivestreamJoinEventTrackedByVideoId,
              [videoId]: false,
            },
          }
        },
        false,
        'unsetLivestreamJoinEventTracked',
      )
    },
  }
}
