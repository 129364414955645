import { PlayerLayout } from '../types'

import type { IPlayerGlobalStateSlice } from './slice'
import { PLAYER_SLICE_KEY } from './slice'
import type { PlayerGlobalState } from './types'

/**
 * @returns Whether there is opened player and whether is in fullscreen mode.
 */
export function hasFullscreenPlayerLayoutSelector(
  state: IPlayerGlobalStateSlice,
): boolean {
  const { playerStates, playerOpenedWidgetId } = state[PLAYER_SLICE_KEY] || {}
  return (
    playerStates[playerOpenedWidgetId || '']?.layout === PlayerLayout.FULLSCREEN
  )
}

/**
 * Get player state by widget id
 * @param widgetId
 * @returns
 */
export function playerStateByWidgetIdSelector(widgetId: string | undefined) {
  return (state: IPlayerGlobalStateSlice) =>
    widgetId ? state[PLAYER_SLICE_KEY]?.playerStates?.[widgetId] : undefined
}

/**
 * Get player state of currently opened player. There can be only one player which is
 * fullscreen/minimized at a time.
 * @param state
 * @returns
 */
export function playerStateOpenedSelector(state: IPlayerGlobalStateSlice) {
  const { playerStates, playerOpenedWidgetId } = state[PLAYER_SLICE_KEY] || {}
  return playerStates[playerOpenedWidgetId || '']
}

/**
 *
 */
export function isLivestreamJoinEventTrackedSelector(videoEncodedId?: string) {
  return (state: IPlayerGlobalStateSlice): boolean =>
    videoEncodedId
      ? Boolean(
          state[PLAYER_SLICE_KEY]?.isLivestreamJoinEventTrackedByVideoId?.[
            videoEncodedId
          ],
        )
      : false
}

/**
 * @returns player model actions
 */
export function playerActionsSelector(
  state: IPlayerGlobalStateSlice,
): Partial<PlayerGlobalState['actions']> {
  if (!state[PLAYER_SLICE_KEY]) {
    return {}
  }
  return state[PLAYER_SLICE_KEY]?.actions || {} // (CS-3310) For some unknown reason, the slice might be undefined. It does not happen to other slices though :man-shrugging:
}
