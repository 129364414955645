/**
 * @fileoverview
 * Helpers to enhance tracking payload with appContext data.
 */

import type { IAppContext } from '@embed/components/helpers'
import { AppContextType, Context } from '@embed/components/helpers'
import type { AnyWidgetAppContext } from '@embed/hooks/useEmbedAppContext'

type AppContextToTrackingDataParams = {
  appContext?: IAppContext | AnyWidgetAppContext
}

type AppContextForTracking = {
  _channel_id?: string
  app_context_type: string
  channel_id?: string
  context: string
  hashtag?: string
  hashtag_id?: string
  page_url?: string
}

/**
 * TODO(GROW-1089): Remove this function once we stop hitting pixel endpoint.
 */
export const appContextToTrackingData = (
  params: AppContextToTrackingDataParams,
): AppContextForTracking | undefined => {
  const { appContext } = params
  const { appContextType } = appContext ?? {}

  switch (appContextType) {
    case AppContextType.CHANNEL_CONTEXT_TYPE: {
      const { channelId, pageUrl } = appContext ?? {}
      return {
        app_context_type: appContextType,
        channel_id: channelId,
        context: Context.CONTEXT_CHANNEL,
        page_url: pageUrl,
      }
    }
    case AppContextType.VIDEO_DETAIL_CONTEXT_TYPE: {
      return {
        app_context_type: appContextType,
        context: Context.CONTEXT_VIDEO_DETAIL,
      }
    }

    // All widget contexts
    default: {
      if (appContext && 'trackingData' in appContext) {
        return appContext.trackingData
      }

      return
    }
  }
}

/**
 *
 */
export const appContextToTrackingDataPixel = ({
  appContext,
}: {
  appContext?: IAppContext | AnyWidgetAppContext | null
}): AppContextForTracking => {
  switch (appContext?.appContextType) {
    case AppContextType.CHANNEL_CONTEXT_TYPE: {
      const { channelId, pageUrl } = appContext
      return {
        app_context_type: appContext.appContextType,
        _channel_id: channelId,
        context: Context.CONTEXT_CHANNEL,
        page_url: pageUrl,
      }
    }

    case AppContextType.VIDEO_DETAIL_CONTEXT_TYPE: {
      return {
        app_context_type: appContext.appContextType,
        context: Context.CONTEXT_VIDEO_DETAIL,
      }
    }

    // All widget contexts
    default: {
      return appContext?.['trackingDataPixel']
    }
  }
}
