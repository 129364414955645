import addSeconds from 'date-fns/addSeconds'

import { FW_FUNNEL_DEFAULT_EXPIRE_TIME_SECONDS } from '@core/tracking/funnelTracking/constants'

/**
 *
 */
export function getCookieExpiryDate(secondsToExpire?: number) {
  return addSeconds(
    new Date(),
    secondsToExpire ?? FW_FUNNEL_DEFAULT_EXPIRE_TIME_SECONDS,
  )
}
