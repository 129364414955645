export enum PlayerLayout {
  MINIMIZED = 'minimized',
  FULLSCREEN = 'fullscreen',
  DEFAULT = 'default',
}

export enum VideoPlayerOpenReason {
  CLOSE_ME = 'close_me',
  STORYBLOCK_DOCK = 'storyblock_dock',
  STORYBLOCK_CONFIG = 'storyblock_config', // pinned or fullscreen
  PROGRAMMATIC = 'programmatic', // share or soft pip
  INTERACTION = 'interaction',
}

export const openReasonPriority: Record<VideoPlayerOpenReason, number> = {
  [VideoPlayerOpenReason.CLOSE_ME]: 1,
  [VideoPlayerOpenReason.STORYBLOCK_DOCK]: 2,
  [VideoPlayerOpenReason.STORYBLOCK_CONFIG]: 3,
  [VideoPlayerOpenReason.PROGRAMMATIC]: 4,
  [VideoPlayerOpenReason.INTERACTION]: 5,
}

export interface PlayerStateSync {
  widget_id: string
  channel?: string
  playlist?: string
  hashtag?: string
  videoId?: string
  layout: PlayerLayout
  openReason: VideoPlayerOpenReason
}
