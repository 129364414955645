import { getGlobalConfig } from '@core/globalConfig'
import { isFalsy } from '@core/helpers/boolean'
import { logError } from '@core/logging'
import { doesBrowserAllowCookies, setCookie } from '@core/storage'
import { setItem } from '@core/storage/localStorage'
import { setCookieWithRootDomain } from '@core/tracking/funnelTracking/cookies/setCookieWithRootDomain'
import type { CookieStoredItem } from '@core/tracking/funnelTracking/cookies/types'
import { getCookieExpiryDate } from '@core/tracking/funnelTracking/helpers'

/**
 * Sets the cookie when cookies are enabled.
 * Sets the cookie value in local storage as fallback.
 */
export function setCookieWithFallback(
  key: string,
  value: string,
  opts: {
    domain?: string
  } = {},
) {
  const { domain = window?.location?.hostname } = opts
  try {
    if (doesBrowserAllowCookies) {
      const storedItem: CookieStoredItem = {
        value,
        createTime: new Date().toISOString(),
      }
      const storedString = JSON.stringify(storedItem)
      const options = {
        expirationDate: getCookieExpiryDate(
          (parseInt(getGlobalConfig().cookies_days) ||
            365) /* Default 1 year */ *
            24 *
            60 *
            60,
        ),
        domain,
      }

      if (
        !getGlobalConfig().cookies_root ||
        !isFalsy(getGlobalConfig().cookies_root)
      ) {
        setCookieWithRootDomain(key, storedString, options)
      } else {
        setCookie(key, storedString, options)
      }
    }

    setItem(key, value)
  } catch (error) {
    logError(error, {
      captureContext: {
        extra: {
          key,
          value,
        },
      },
    })
  }
}
