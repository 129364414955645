import type { IAPI_Channel } from '@core/api'
import type { IParams } from '@core/types'
import type { FWPageBlock, PageBlock } from '@microsites/types/block'

type MicrositeChannel = IAPI_Channel['channel']

export interface MicrositeConfig extends MicrositeChannel {
  page?: Api_Page
}

export interface Api_Page {
  analytics_tags: string
  blocks: Array<PageBlock | FWPageBlock>
  channel_id: string
  id: string
  is_draft: boolean
  locale: string
  metadata: PageMeta
  path: string
  published_at: string
  title: string
}

export enum UITheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface PageMeta {
  meta_description?: string
  meta_tags?: string
  config?: {
    ui_theme?: UITheme.DARK | UITheme.LIGHT
    ui_font?: string
    ui_border_style?: IParams['ui_border_style']
    ui_button_color?: IParams['ui_button_color']
    ui_button_font_color?: IParams['ui_button_font_color']
    ui_full_cta?: IParams['ui_full_cta']
  }
}
