import getConfig from 'next/config'

import * as cookies from '@core/storage/cookies'
import type { StorageKeyEssentialEnum } from '@core/storage/types'

const domain = getConfig()?.publicRuntimeConfig.domain

export const CCPA_OPT_OUT = 'OptedOutOfSalePersonalInformationOnFirework'

function shouldSetDomain(): boolean {
  return !!domain && !!window.location.origin.match(domain)
}

// Set the cookie domain if on fw.tv or username.fw.tv so it can be shared
// between subdomains
function cookieDomain(): string {
  if (shouldSetDomain()) {
    return `;domain=.${domain}`
  }
  return ''
}

/**
 *
 */
export function getCookie(name: StorageKeyEssentialEnum): string | null {
  return cookies.getCookie(name) || null
}

/**
 *
 */
export function setCookie(
  name: StorageKeyEssentialEnum,
  value: string,
  expirationDate?: Date,
) {
  cookies.setCookie(name, value, {
    expirationDate,
    domain: cookieDomain(),
  })
}

/**
 *
 */
export function removeCookie(name: StorageKeyEssentialEnum): void {
  cookies.removeCookie(name, {
    domain: cookieDomain(),
  })
}
