/* eslint-disable zeffo/no-window-storage */
/**
 * @fileoverview Wrapper around localStorage methods with ability to expire
 * and keep track of creation time of record.
 */

import { logWarning } from '@core/logging'
import { localStorages } from '@core/storage/instances/localStorages'

import { getLocalStorage } from './getStorage'

export interface ILocalStorageStoredItem<T> {
  value: T
  createTime?: string
  expire?: string
}

/** @returns The stored item value from the stored JSON string. */
export function parseStoredString<T>(
  storedString: string | null,
): T | undefined {
  if (!storedString) {
    return
  }
  try {
    const storedItem = JSON.parse(storedString) as ILocalStorageStoredItem<T>
    if (storedItem.expire) {
      const expireDate = new Date(storedItem.expire)
      if (expireDate < new Date()) {
        return
      }
    }
    return storedItem.value
  } catch (e) {
    logWarning('Failed to parse stored string to JSON', storedString)
    return
  }
}

/**
 * Set an item to local storage. The item will contain also create time of
 * record and expiration if it is set.
 */
export function setItem<T>(key: string, value: T, expire?: Date) {
  const storedItem: ILocalStorageStoredItem<T> = {
    value,
    createTime: new Date().toISOString(),
  }
  if (expire) {
    storedItem.expire = expire.toISOString()
  }

  getLocalStorage().setItem(key, JSON.stringify(storedItem))
}

/**
 * Retrieves the raw item object from local storage. This is useful if you need
 * to process the metadata in a different way than the default getItem() method.
 *
 * @returns The raw item from storage, or undefined if it doesn't exist. It
 * doesn't check for expiration.
 */
export function getRawItem<T>(
  key: string,
): ILocalStorageStoredItem<T> | undefined {
  const storedString = getLocalStorage().getItem(key)
  if (!storedString) {
    return
  }
  try {
    return JSON.parse(storedString) as ILocalStorageStoredItem<T>
  } catch (e) {
    return
  }
}

/**
 * Retrieves the item value from storage.
 *
 * @returns The item value, or undefined if it doesn't exist or has expired.
 */
export function getItem<T>(key: string): T | undefined {
  const storedString = getLocalStorage().getItem(key)
  return parseStoredString(storedString)
}

/**
 * Removes the item from local storage.
 */
export function removeItem(key: string) {
  try {
    getLocalStorage().removeItem(key)
  } catch (error) {
    // Pass silently
  }
}

/** Clears all storage instances for test purposes */
export const TEST_ONLY = {
  clearLocalStorages() {
    Object.values(localStorages).forEach((storage) => storage.clearForTest())
  },
}
