/* eslint-disable @typescript-eslint/no-var-requires */
import { getInstance } from '@core/i18n/setup'

const i18next = getInstance({
  ns: ['next', 'translation'],
})

// Bundled translations will not be
// requested asynchronously
i18next.addResourceBundle(
  'en',
  'translation',
  require('@trans/en/translation.json'),
)
i18next.addResourceBundle('en', 'next', require('@trans/en/next.json'))

export default i18next
