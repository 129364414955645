import { getGlobalConfig } from '@core/globalConfig'
import { isTruthy } from '@core/helpers/boolean'
import { logError } from '@core/logging'
import { StorageKeyEssentialEnum } from '@core/storage/types'
import {
  getCookieWithFallback,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'
import type { Consent } from '@core/tracking/types'
import { consents } from '@core/tracking/types'

export const CONSENT_COOKIE_KEY = StorageKeyEssentialEnum.fw_consent

function isValidConsent(consent: unknown): consent is Consent {
  if (!consent || !consents.includes(consent as Consent)) {
    logError(
      new Error(
        `Value ${consent} provided for content is invalid. Allowed consents: ${consents.join(
          ', ',
        )}`,
      ),
      {
        captureContext: {
          extra: {
            consent,
          },
        },
      },
    )
    return false
  }

  return true
}

/**
 * Function responsible for validating and storing of user consent.
 */
export function setConsent(consent?: unknown) {
  if (!isValidConsent(consent)) {
    return
  }
  setCookieWithFallback(CONSENT_COOKIE_KEY, consent)
}

/**
 * @returns The consent previously set by user
 */
export function getConsent(): Consent | undefined {
  return getCookieWithFallback(CONSENT_COOKIE_KEY)?.value as Consent | undefined
}

/**
 * @returns Whether to skip sending the event if the consent level doesn't
 * match.
 */
export function shouldBlockEndpointWithConsent(
  endpointConsent: Consent,
): boolean {
  const consent = getConsent()
  const isOptInTracking = isTruthy(getGlobalConfig().tracking_opt_in)
  // We skip non-essential events if user set only essential
  // or we use opt_in_tracking and user did not set consent yet(EU)
  return (
    endpointConsent === 'non-essential' &&
    (consent === 'essential' || (isOptInTracking && !consent))
  )
}
