import type { MicrositeConfig } from '@microsites/types'

const TAG_MANAGER_IDENTIFIER = '<!-- Google Tag Manager -->\n'
const TAG_MANAGER_END_IDENTIFIER = '\n<!-- End Google Tag Manager -->'
const NO_SCRIPT_IDENTIFIER = '<!-- Google Tag Manager (noscript) -->\n'
const NO_SCRIPT_END_IDENTIFIER = '\n<!-- End Google Tag Manager (noscript) -->'

const getGoogleTags = (tags?: string): { headTag: string; bodyTag: string } => {
  if (!tags) {
    return { headTag: '', bodyTag: '' }
  }
  const headStartIndex = tags.indexOf(TAG_MANAGER_IDENTIFIER)
  const headEndIndex = tags.indexOf(TAG_MANAGER_END_IDENTIFIER)
  const headTag = tags
    .slice(headStartIndex, headEndIndex)
    .replace(TAG_MANAGER_IDENTIFIER, '')
    .replace(/\n/g, '')
  const bodyStartIndex = tags.indexOf(NO_SCRIPT_IDENTIFIER)
  const bodyEndIndex = tags.indexOf(NO_SCRIPT_END_IDENTIFIER)
  const bodyTag = tags
    .slice(bodyStartIndex, bodyEndIndex)
    .replace(NO_SCRIPT_IDENTIFIER, '')
    .replace(/\n/g, '')

  return {
    headTag,
    bodyTag,
  }
}

/**
 * Helper function to parse analytics tags from microsite and page configs.
 * @param micrositeConfig The microsite config object.
 * @returns An object with two properties: headTags and bodyTags
 */
export const getAnalyticsTags = (micrositeConfig?: MicrositeConfig) => {
  let headTags = ''
  let bodyTags = ''
  if (!micrositeConfig) {
    return {
      headTags,
      bodyTags,
    }
  }
  const { config, page } = micrositeConfig
  const micrositeTags = config?.analytics_tags
  const pageTags = page?.analytics_tags

  // if microsite has analytics tags, check if they are google tags and add to
  // head/body arrays. If not, add to head array.
  if (micrositeTags) {
    const { headTag: micrositeHeadTag, bodyTag: micrositeBodyTag } =
      getGoogleTags(micrositeTags)
    if (micrositeHeadTag && micrositeBodyTag) {
      headTags += micrositeHeadTag
      bodyTags += micrositeBodyTag
    } else {
      bodyTags += micrositeTags
    }
  }

  // if page has analytics tags, check if they are google tags and add to
  // head/body arrays. If not, add to head array.
  if (pageTags) {
    const { headTag: pageHeadTag, bodyTag: pageBodyTag } =
      getGoogleTags(pageTags)
    if (pageHeadTag && pageBodyTag) {
      headTags += pageHeadTag
      bodyTags += pageBodyTag
    } else {
      bodyTags += pageTags
    }
  }

  return {
    headTags: headTags.slice('<script>'.length, -'</script>'.length),
    bodyTags,
  }
}
