export type { CookiesCache } from './classes/CookiesCache'
export { doesBrowserAllowCookies } from './consent'
export { getCookie, removeCookie, setCookie } from './cookies'
export {
  getLocalStorage,
  getMemoryStorage,
  getSessionStorage,
} from './getStorage'

// Note: Do not re-export React components or hooks here.
