interface IProps {
  font?: string
}

/**
 *
 */
const FontHead: React.FC<IProps> = ({ font }) => {
  let fontUrl = ''
  if (font) {
    fontUrl = `https://fonts.googleapis.com/css2?family=${font.replace(
      ' ',
      '+',
    )}:wght@400;500;600;700&display=swap`
  }
  return fontUrl ? (
    <>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link rel="preload" as="style" href={fontUrl} />
      <link href={fontUrl} rel="stylesheet" />
    </>
  ) : null
}
export default FontHead
