/* eslint-disable zeffo/no-untranslated-labels */
import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation, withTranslation } from 'react-i18next'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { StorageKeyEssentialEnum } from '@core/storage/types'
import { sendPixelTrackingWithSession } from '@core/tracking'
import {
  propWithBreakpoints,
  SmallAndAbove,
  XSmallAndBelow,
} from '@microsites/components/MediaQuery'
import { useCookieStorage } from '@microsites/hooks/useCookieStorage'
import { ONBOARDING_VIEW_DISCLOSURE } from '@microsites/tracking/endpoints'
import { COOKIE_CONSENT_MODAL } from '@player/testIds'

const COOKIE_CONSENT = StorageKeyEssentialEnum.fw_tv_cookie_consent_accepted
const COOKIE_MAX_DAYS = 365

const Wrapper = styled.div<{ isExpanded: boolean }>`
  align-items: center;
  background: #2b2b2b;
  border-radius: 6px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: ${({ isExpanded }) => (isExpanded ? 'column' : 'row')};
  flex-wrap: nowrap;
  justify-content: stretch;
  left: 50%;
  max-width: 916px;
  position: fixed;
  transform: translateX(-50%);
  width: 95%;
  z-index: 3;
  padding: 16px;
  gap: 12px;
  bottom: 90px;
  @media (min-width: 576px) {
    gap: 16px;
    bottom: 36px;
  }
`

const ConsentText = styled.div`
  color: #9b9b9b;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
`
const ConsentReadMore = styled.button`
  background: none;
  border: 0;
  color: white;
  font-weight: bold;
  padding: 0;
  display: inline;
  cursor: pointer;
`

const AcceptButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  min-width: 0px;
  border: 0px;
  border-radius: 500px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 32px;
  text-align: center;
  text-decoration: none;
  background: linear-gradient(
      170deg,
      rgb(255, 114, 13) 16%,
      rgb(254, 23, 122) 61%,
      rgb(164, 15, 244) 108%
    )
    rgb(255, 112, 97);
  line-height: 1.5;
  height: 30px;
  white-space: nowrap;
`

const CloseButton = styled.img`
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
`

const CookieText = withTranslation()(() => {
  const PassLink = ({ children }) => {
    return <Link href="/privacy">{children[0]}</Link>
  }
  return (
    <ConsentText>
      <Trans i18nKey="next:onboard~CookieConsent">
        We use cookies to enhance your experience, for analytics and to show you
        offers tailored to your interest on our site and third-party sites. We
        may share your information with our advertising and analytic partners.
        Find out more about cookies by reading our{' '}
        <PassLink>Privacy Policy</PassLink> which contains further information
        about the cookies and other technologies we use and information about
        how to disable them. By clicking accept you agree to our use of cookies
        and similar technologies.
      </Trans>
    </ConsentText>
  )
})

const CookieTextShrink = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <ConsentText>
      <Trans i18nKey="next:onboard~CookieConsentShort">
        We use cookies to enhance your experience, for analytics and to show you
        offers tailored to your...
      </Trans>
      <ConsentReadMore onClick={onClick}>{t('Read more')}</ConsentReadMore>
    </ConsentText>
  )
}

/**
 * Component to show consent modal to accept/decline cookies
 */
const CookieConsent = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const [isExpanded, setIsExpanded] = useState(false)

  const [isConsentAccepted, setIsConsentAccepted] =
    useCookieStorage(COOKIE_CONSENT)
  const [isConsentDismissed, setIsConsentDismissed] =
    useState(isConsentAccepted)

  useEffect(() => {
    if (isConsentAccepted) {
      sendPixelTrackingWithSession({
        endpoint: ONBOARDING_VIEW_DISCLOSURE,
        host: undefined,
        data: {
          event_properties: {
            disclosure: 'cookie',
          },
        },
      })
    }
  }, [isConsentAccepted])

  const expandCookieConsent = useCallback(() => setIsExpanded(true), [])

  const acceptCookieConsent = useCallback(
    () => setIsConsentAccepted('1', { expirationDays: COOKIE_MAX_DAYS }),
    [setIsConsentAccepted],
  )

  const dismissCookieConsent = useCallback(() => setIsConsentDismissed('1'), [])

  // Make sure to render only on client side to prevent SSR hydration mismatch
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const component = (
    <Wrapper isExpanded={isExpanded} data-testid={COOKIE_CONSENT_MODAL}>
      <CloseButton
        src={require('@images/close_icon.svg')}
        onClick={dismissCookieConsent}
      />

      <div>
        <SmallAndAbove>
          <CookieText />
        </SmallAndAbove>
        <XSmallAndBelow>
          {isExpanded ? (
            <CookieText />
          ) : (
            <CookieTextShrink onClick={expandCookieConsent} />
          )}
        </XSmallAndBelow>
      </div>
      <div
        css={[
          css`
            min-width: auto;
          `,
          propWithBreakpoints('padding-left', ['16px', '28px']),
          propWithBreakpoints('padding-right', ['16px', '28px']),
        ]}
      >
        <AcceptButton onClick={acceptCookieConsent}>
          {t('I accept')}
        </AcceptButton>
      </div>
    </Wrapper>
  )

  return isClient &&
    !isConsentDismissed &&
    !isConsentAccepted &&
    !router.asPath.startsWith('/tv') &&
    !router.asPath.startsWith('/embed') &&
    !router.asPath.includes('is_preview=true')
    ? createPortal(component, document.body)
    : null
}

export default CookieConsent
