/**
 * Clone https://github.com/getsentry/sentry-javascript/blob/master/packages/core/src/integrations/inboundfilters.ts
 * The Sentry default integration does not work with BrowserClient. We created
 * our own event processor that brings back the behavior.
 *
 * Note: ignoreInternal error is not ported because we don't use the option.
 * Note: event URL definition is different from the original implementation. See
 * `getEventFilterUrl` comment for more details.
 */

import type { Event, Scope } from '@sentry/types'

interface InboundFiltersOptions {
  allowUrls: Array<string | RegExp>
  denyUrls: Array<string | RegExp>
  ignoreErrors: Array<string | RegExp>
}

/**
 * Adds InboundFilter to the current scope. InboundFilter checks filter options
 * and decides whether to drop the event.
 */
export function addInboundFilter(scope: Scope): void {
  scope.addEventProcessor((event) => {
    const client = scope.getClient()
    const options = client?.getOptions() ?? {}
    if (shouldDropEvent(event, options)) {
      return null
    }
    return event
  })
}

function shouldDropEvent(
  event: Event,
  options: Partial<InboundFiltersOptions>,
): boolean {
  if (isIgnoredError(event, options.ignoreErrors)) {
    return true
  }
  if (isDeniedUrl(event, options.denyUrls)) {
    return true
  }
  if (!isAllowedUrl(event, options.allowUrls)) {
    return true
  }
  return false
}

function isIgnoredError(
  event: Event,
  ignoreErrors?: Array<string | RegExp>,
): boolean {
  if (!ignoreErrors || !ignoreErrors.length) {
    return false
  }

  return getPossibleEventMessages(event).some((message) =>
    ignoreErrors.some((pattern) => isMatchingPattern(message, pattern)),
  )
}

function isDeniedUrl(event: Event, denyUrls?: Array<string | RegExp>): boolean {
  if (!denyUrls || !denyUrls.length) {
    return false
  }
  const url = getEventFilterUrl(event)
  return !url
    ? false
    : denyUrls.some((pattern) => isMatchingPattern(url, pattern))
}

function isAllowedUrl(
  event: Event,
  allowUrls?: Array<string | RegExp>,
): boolean {
  if (!allowUrls || !allowUrls.length) {
    return true
  }
  const url = getEventFilterUrl(event)
  return !url
    ? true
    : allowUrls.some((pattern) => isMatchingPattern(url, pattern))
}

const objectToString = Object.prototype.toString

function isBuiltin(wat: unknown, ty: string): boolean {
  return objectToString.call(wat) === `[object ${ty}]`
}

function isString(wat: unknown): wat is string {
  return isBuiltin(wat, 'String')
}

function isRegExp(wat: unknown): wat is RegExp {
  return isBuiltin(wat, 'RegExp')
}

/**
 * Checks if the value matches a regex or includes the string
 * @param value The string value to be checked against
 * @param pattern Either a regex or a string that must be contained in value
 */
function isMatchingPattern(value: string, pattern: RegExp | string): boolean {
  if (!isString(value)) {
    return false
  }

  if (isRegExp(pattern)) {
    return pattern.test(value)
  }
  if (typeof pattern === 'string') {
    return value.indexOf(pattern) !== -1
  }
  return false
}

function getPossibleEventMessages(event: Event): string[] {
  if (event.message) {
    return [event.message]
  }
  if (event.exception) {
    try {
      const { type = '', value = '' } =
        (event.exception.values && event.exception.values[0]) || {}
      return [`${value}`, `${type}: ${value}`]
    } catch {
      return []
    }
  }
  return []
}

/**
 * Warning! This function works differently from the original sentry
 * implementation.
 *
 * A traditional web application sets a global sentry hub and filters out errors
 * based on JS script URL. That's why the original `getEventFilterUrl` finds the
 * url from stacktrace frames.
 *
 * We embed our widget to the customer site and creates our own sentry hub.
 * Often we want to use `denyUrl` to filter out customer sites that
 * misconfigured their integration. In our case, the event request URL is what
 * we should look for.
 */
function getEventFilterUrl(event: Event): string | null {
  try {
    return event.request?.url ?? null
  } catch {
    return null
  }
}
