/**
 * @fileoverview
 * Wrapper around window storage methods so we can avoid using them
 * in our code directly. This is necessary for Shoppable Ads which do
 * not allow usage of window storage.
 */

import { getFWN } from '@core/fwn'
import { localStorages } from '@core/storage/instances/localStorages'
import { memoryStorage } from '@core/storage/instances/memoryStorage'
import { sessionStorages } from '@core/storage/instances/sessionStorages'

import { getConsentLevelByKey } from './getConsentByKey'
import type {
  LocalOrSessionStorageReturnInstanceByKey,
  StorageKey,
} from './types'

const consentCache = new Set<
  (typeof sessionStorages)[keyof typeof sessionStorages]
>()

/**
 * Helps to return cached instance of localStorages map
 * @returns
 */
function getLocalStorageInstances() {
  return getFWN()?.localStorages ?? localStorages
}

/**
 * Helps to return cached instance of sessionStorages map
 * @returns
 */
function getSessionStorageInstances() {
  return getFWN()?.sessionStorages ?? sessionStorages
}

/**
 * Helps to return proper instance of local/session storage based on a key
 * affiliation to consent level.
 * @param key
 * @param instances
 * @returns
 */
export function getInstance<K extends StorageKey>(
  key: K,
  instances: typeof localStorages | typeof sessionStorages,
): LocalOrSessionStorageReturnInstanceByKey[K] {
  const consent = getConsentLevelByKey(key)
  const instance = instances[consent]

  if (!consentCache.has(instance) && getFWN()?.cookies?.registerConsentCache) {
    getFWN()?.cookies?.registerConsentCache(instance, consent)
    consentCache.add(instance)
  }

  return instance
}

/**
 * LocalStorage
 */

const localStorageKeyRouter = Object.freeze({
  getItem(key: string) {
    return getInstance(key, getLocalStorageInstances()).getItem(key)
  },
  setItem(key: string, value: string) {
    return getInstance(key, getLocalStorageInstances()).setItem(key, value)
  },
  removeItem(key: string) {
    return getInstance(key, getLocalStorageInstances()).removeItem(key)
  },
  get length() {
    return Object.values(getLocalStorageInstances()).reduce((acc, instance) => {
      acc = acc + instance.length
      return acc
    }, 0)
  },
  clear() {
    Object.values(getLocalStorageInstances()).forEach((instance) => {
      instance.clear()
    })
  },
  key() {
    throw new Error('Not implemented')
  },
})

/**
 * Get access to local storage instance
 * @returns
 */
export function getLocalStorage(): Storage {
  return localStorageKeyRouter
}

/**
 * SessionStorage
 */

const sessionStorageRouter = Object.freeze({
  getItem(key: string) {
    return getInstance(key, getSessionStorageInstances()).getItem(key)
  },
  setItem(key: string, value: string) {
    return getInstance(key, getSessionStorageInstances()).setItem(key, value)
  },
  removeItem(key: string) {
    return getInstance(key, getSessionStorageInstances()).removeItem(key)
  },
  get length() {
    return Object.values(getSessionStorageInstances()).reduce(
      (acc, instance) => {
        acc = acc + instance.length
        return acc
      },
      0,
    )
  },
  clear() {
    Object.values(getSessionStorageInstances()).forEach((instance) => {
      instance.clear()
    })
  },
  key() {
    throw new Error('Not implemented')
  },
})

/**
 * Get access to session storage instance
 * @returns
 */
export function getSessionStorage(): Storage {
  return sessionStorageRouter
}

/**
 * MemoryStorage
 */

/**
 * Get access to memory storage instance which mimics localStorage
 * @returns
 */
export function getMemoryStorage() {
  return getFWN()?.memoryStorage ?? memoryStorage
}
