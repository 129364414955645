/**
 * @fileoverview
 * Funnel tracking related tools.
 *
 * NOTE: Do not export React stuff like hooks or components
 * in this file as it may unnecessary increase the analytic.js
 * bundle size by including Preact and Emotion.
 */
// Constants
export { FW_FUNNEL_DEFAULT_EXPIRE_TIME_SECONDS } from './constants'

// Cookies
export { getCookieWithFallback } from './cookies/getCookieWithFallback'
export { removeCookieAndFallback } from './cookies/removeCookieAndFallback'
export { setCookieWithFallback } from './cookies/setCookieWithFallback'

// Trackers
export { useSetBusinessId } from './hooks/useSetBusinessId'
export { persistBusinessMembershipId } from './trackers/persistBusinessMembershipId'
export { persistChannelId } from './trackers/persistChannelId'
export { persistChatChannelId } from './trackers/persistChatChannelId'
export { persistGuestId } from './trackers/persistGuestId'
export { persistLastEngagementTimestamp } from './trackers/persistLastEngagementTimestamp'
export { persistUTM } from './trackers/persistUTM'
export { persistVideoId } from './trackers/persistVideoId'
export { EnumFunnelTrackingCookies, EnumUtmKeys } from './types'
