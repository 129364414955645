/**
 * Get the FWN object from the window. If the current script
 * runs in an iframe (PIP), get the instance from parent window.
 *
 * Note: Ideally this implementation should have no dependencies to
 * prevent any circular dependency problem. Hence the decision to
 * check `window.parent._fwn` instead of using `isInPIPIframe`
 */
export function getFWN() {
  if (typeof window !== 'undefined') {
    try {
      if (window.parent && window.parent._fwn) {
        return window.parent._fwn
      }
    } catch (err) {}
    return window._fwn
  }
  return undefined
}
