export enum AppContextType {
  // Widget contexts
  EMBED_ADD_TO_CALENDAR_CONTEXT_TYPE = 'embed_add_to_calendar',
  EMBED_BUTTON_WRAPPER = 'embed_button_wrapper',
  EMBED_CONTEXT_TYPE = 'embed_player',
  EMBED_GRID_CONTEXT_TYPE = 'embed_grid',
  EMBED_HERO_CONTEXT_TYPE = 'embed_hero',
  EMBED_PLAYER_TYPE = 'embed_player_widget',
  EMBED_SHARE_CONTEXT_TYPE = 'embed_share',
  EMBED_STORYBLOCK_CONTEXT_TYPE = 'embed_storyblock',
  EMBED_STORYLINK_CONTEXT_TYPE = 'embed_storylink',
  EMBED_SHOWROOM_SCHEDULE_CONTEXT_TYPE = 'embed_showroom_schedule',
  // fw.tv contexts (will be deprecated)
  VIDEO_DETAIL_CONTEXT_TYPE = 'video_detail_player',
  CHANNEL_CONTEXT_TYPE = 'channel_player',
  // Headless UI
  HEADLESS_FEED = 'headless_feed',
}

export enum Context {
  CONTEXT_CHANNEL = 'channel',
  CONTEXT_HASHTAG = 'hashtag',
  CONTEXT_PLAYLIST = 'playlist',
  CONTEXT_PLAYLIST_COLLECTION = 'playlist_collection',
  CONTEXT_VIDEO_DETAIL = 'video_detail',
  CONTEXT_SKUS = 'skus',
  CONTEXT_SHOWROOM_SCHEDULE = 'showroom_schedule',
}

export const DESKTOP_MEDIAQUERY = 'min-width: 1024px' // 690px // 376px
export const TABLET_MEDIAQUERY = 'min-width: 690px' // 690px // 376px

export type IAppContextHashtag = { name: string; id: string }
export interface IAppContext {
  appContextType: AppContextType
  adConfig?: undefined
  context?: Context
  username?: string
  playlist_id?: string
  tag?: string
  hashtag?: IAppContextHashtag
  access_token?: string
  guest_id?: string
  guestId?: string
  isCustomDomain?: string
  appId?: string
  pageUrl?: string
  variant?: string
  viewportVideoIds?: string
  channelId?: string
  channel?: any // TODO [CS-3282] give a proper type
  oauthClientId?: string
  oauthClientEncodedId?: string
}

export { resizeThumbnailUrl } from './resizeThumbnailUrl'
