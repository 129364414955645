import type { Api_Page, MicrositeConfig } from '@microsites/types'

const SANS_SERIF_CUSTOM_FONTS = [
  'Lato',
  'Montserrat',
  'Open Sans',
  'Oswald',
  'Poppins',
  'Roboto',
]

export const DEFAULT_FONT_FAMILY = `'Avenir Next', -apple-system, 
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', 
    Arial, sans-serif,'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`

/**
 * Helper function to get font family from page config, or the channel config.
 * If no font is set, it will return the default font.
 */
export const getMicrositeFont = (
  ssrChannel?: MicrositeConfig,
  ssrPage?: Api_Page,
): { fontFromConfig: string; fontFamily: string } => {
  let fontFromConfig =
    ssrPage?.metadata?.config?.ui_font ?? ssrChannel?.config.ui_font ?? ''
  if (fontFromConfig) {
    // Below is necessary to use Google Fonts. So far, if font is not
    // sans-serif, it is serif if further fonts are added, this may need to be
    // updated.
    fontFromConfig = SANS_SERIF_CUSTOM_FONTS.includes(fontFromConfig)
      ? fontFromConfig.concat(', sans-serif')
      : fontFromConfig.concat(', serif')
  }
  const fontFamily = fontFromConfig
    ? fontFromConfig.concat(', ', DEFAULT_FONT_FAMILY)
    : DEFAULT_FONT_FAMILY
  return {
    fontFromConfig,
    fontFamily,
  }
}
