import { getNextJsPublicEnv } from '@src/helpers'

/**
 *  Gets locale from window.navigator
 */
export const locale =
  // Safe to ignore linter rule to not change the previous behaviour
  // unless "Data team" shows interest in collecting widget lang
  // as well. - @stefanbackor
  // eslint-disable-next-line zeffo/no-navigator-language
  typeof window !== 'undefined' ? window.navigator.language : undefined

/**
 *  @returns The product name: embed.web.naboo or zeffo.web.naboo.
 */
export function getTrackingProduct(): string | undefined {
  return getNextJsPublicEnv('PRODUCT') || process.env.PRODUCT
}
