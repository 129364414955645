import { CookiesCache } from '../classes/CookiesCache'
import { StorageMemoryCache } from '../classes/StorageMemoryCache'
import { StorageConsentLevel } from '../types'

/**
 * Create proxy for storage instances. Instance for given consent level
 * will be lazy-loaded and cached on demand.
 * @param cls
 * @param mapping
 * @returns
 */
export function createProxy<T extends object>(
  cls: typeof StorageMemoryCache | typeof CookiesCache,
  mapping: Record<
    StorageConsentLevel,
    | ConstructorParameters<typeof StorageMemoryCache>
    | ConstructorParameters<typeof CookiesCache>
  >,
): T {
  const handler = {
    get: (cache: T, prop: StorageConsentLevel) => {
      if (!cache[prop]) {
        if (!mapping[prop]) {
          return undefined
        }
        if (cls === StorageMemoryCache) {
          const obj = new cls(
            ...(mapping[prop] as ConstructorParameters<
              typeof StorageMemoryCache
            >),
          )
          cache[prop] = obj
        }
        if (cls === CookiesCache) {
          cache[prop] = new cls(
            ...(mapping[prop] as ConstructorParameters<typeof CookiesCache>),
          )
        }
      }

      return cache[prop]
    },
  }

  return new Proxy<T>(
    {
      [StorageConsentLevel.NOOP]: undefined,
      [StorageConsentLevel.ESSENTIAL]: undefined,
      [StorageConsentLevel.FUNCTIONAL]: undefined,
      [StorageConsentLevel.PERFORMANCE]: undefined,
      [StorageConsentLevel.TARGETING]: undefined,
    } as T,
    handler,
  )
}
