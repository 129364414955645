import type { IPWAState } from './state'

/**
 *
 */
export const actions = () => ({
  setContext: (state: IPWAState, action) => {
    const { appContext } = action

    // Resets engagement tracking state when the context changes
    return {
      appContext,
      engagement: {},
    }
  },
})
