/**
 * @fileoverview
 *
 * Implementation of localStorage as a map of `StorageMemoryCache`
 * instances for consent level.
 */

import { StorageMemoryCache } from '../classes/StorageMemoryCache'
import {
  StorageConsentLevel,
  StorageKeyEssentialEnum,
  StorageKeyFunctionalEnum,
  StorageKeyPerformanceEnum,
  StorageKeyTargetingEnum,
} from '../types'

import { createProxy } from './proxy'
import type { ReturnStorage } from './types'

let storage: Storage | undefined
try {
  // eslint-disable-next-line zeffo/no-window-storage
  storage = typeof window !== 'undefined' ? window.localStorage : undefined
} catch (e) {}

export const mapping: Record<
  StorageConsentLevel,
  ConstructorParameters<typeof StorageMemoryCache>
> = {
  [StorageConsentLevel.NOOP]: [],
  [StorageConsentLevel.ESSENTIAL]: [
    Object.values(StorageKeyEssentialEnum),
    storage,
  ],
  [StorageConsentLevel.FUNCTIONAL]: [
    Object.values(StorageKeyFunctionalEnum),
    storage,
  ],
  [StorageConsentLevel.PERFORMANCE]: [
    Object.values(StorageKeyPerformanceEnum),
    storage,
  ],
  [StorageConsentLevel.TARGETING]: [
    Object.values(StorageKeyTargetingEnum),
    storage,
  ],
}

export const localStorages = createProxy<ReturnStorage>(
  StorageMemoryCache,
  mapping,
)
