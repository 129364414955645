import type { Scope } from '@sentry/browser'

/**
 * Adds UA to sentry events. This is copied from sentry user agent
 * integration.
 * https://github.com/getsentry/sentry-javascript/blob/a6f8dc26a4c7ae2146ae64995a2018c8578896a6/packages/browser/src/integrations/useragent.ts
 * It has to be manually added because Sentry integration doesn't work
 * with BrowserClient (getsentry/sentry-javascript#3271)
 */
export const addUserAgent = (scope: Scope): void => {
  if (!global.navigator && !global.location && !global.document) {
    return
  }
  scope.addEventProcessor((event) => {
    const url = event.request?.url || global.location?.href
    const { referrer } = global.document || {}
    const { userAgent } = global.navigator || {}

    const headers = {
      ...event.request?.headers,
      ...(referrer && { Referer: referrer }),
      ...(userAgent && { 'User-Agent': userAgent }),
    }
    const request = { ...(url && { url }), headers }

    return { ...event, request }
  })
}
