import { GLOBAL_CONFIG_KEY } from '@core/globalConfig/constants'
import { logError } from '@core/logging'
import { safeLogVariable } from '@core/logging/helpers'

import {
  parseAllScriptConfigs,
  parseCurrentScriptConfigs,
} from './parseScriptConfigs'

const processedScriptTags = new Set<HTMLScriptElement>()
let reportedConfigError = false

/**
 * Parses global configs.
 * @returns The parsed global configs.
 */
export function parseGlobalConfig(
  configs: Map<string, string>,
): Record<string, string> {
  // Clients with the legacy integration do not define global config with the
  // latest standard. For backward-compatibility, we need to keep the legacy
  // behavior and parse the global config from the current script tag.
  parseCurrentScriptConfigs(configs, processedScriptTags)

  parseAllScriptConfigs(configs, processedScriptTags)

  let updatedConfig = {}
  // [ZE-477] configs seem to be not iterable at times so adding a try/catch to mitigate the issue
  try {
    for (const [key, value] of configs) {
      updatedConfig = { ...updatedConfig, [key]: value }
    }
  } catch (e) {
    if (!reportedConfigError) {
      logError(new Error('Cannot iterate over configs.'), {
        captureContext: {
          extra: {
            message: String(e),
            configs: safeLogVariable(configs),
          },
        },
      })

      reportedConfigError = true
    }
  }

  return updatedConfig
}

/**
 * @returns The global config. Marging the cache with other script tags.
 * Because of OTO-1194 where wordpress and other third party CMS are adding their own params into the script tag,
 * we need to parse all the script tags and merge the global config from multiple script tags into one.
 */
export function getGlobalConfig(
  win: Window | undefined = typeof window === 'undefined' ? undefined : window,
): Record<string, string> {
  if (typeof win === 'undefined') {
    return {}
  }

  const configsMap = new Map<string, string>()

  if (win[GLOBAL_CONFIG_KEY]) {
    for (const [key, value] of Object.entries(win[GLOBAL_CONFIG_KEY])) {
      configsMap.set(key, `${value}`)
    }
  }

  const parsedConfig = parseGlobalConfig(configsMap)
  Object.defineProperty(win, GLOBAL_CONFIG_KEY, {
    value: { ...configsMap, ...parsedConfig },
    writable: true,
    configurable: true,
  })

  return parsedConfig
}
