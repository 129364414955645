import merge from '@core/lodash/merge'
import { pruneNilValues } from '@core/object'
import { getSession } from '@core/tracking'
import { getGlobalEventProperties } from '@core/tracking/senders/helpers/globalEventProperties'
import { prepareGuestFields } from '@core/tracking/senders/helpers/prepareGuestFields'
import { prepareSessionFields } from '@core/tracking/senders/helpers/prepareSessionFields'
import type { ISendPixelTracking } from '@core/tracking/types'

import { getGlobalTrackingFields } from './helpers/globalTrackingFields'
import { sendPixelTracking } from './sendPixelTracking'

/**
 * Sends a pixel tracking event to the backend. It will attach the session data
 * and the client metadata to the payload.
 */
export const sendPixelTrackingWithSession = async ({
  endpoint,
  data = {},
  host,
}: ISendPixelTracking): Promise<Response | boolean | void> => {
  const session = getSession()
  if (!session) {
    return
  }

  const pixelTrackingFields = {
    ...getGlobalTrackingFields(),
    event_properties: getGlobalEventProperties(),
  }

  const mergedData = pruneNilValues(
    merge(
      {},
      pixelTrackingFields,
      prepareSessionFields(session),
      prepareGuestFields(),
      data,
    ),
  )

  return sendPixelTracking({ endpoint, data: mergedData, host })
}
