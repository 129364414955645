import type { Slice } from './slice'
import { SLICE_KEY } from './slice'

/**
 * Partialize channels state for local storage
 */
export function partialize(state: Slice) {
  return {
    [SLICE_KEY]: {
      byId: state[SLICE_KEY].byId,
    },
  }
}

/**
 * Merges the persisted data with the current state
 */
export function merge(persistedState: Slice, currentState: Slice) {
  return {
    [SLICE_KEY]: {
      ...currentState[SLICE_KEY],
      ...persistedState[SLICE_KEY],
    },
  }
}
