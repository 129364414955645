import type { ISharedGlobalState } from './state'

/**
 * Partial state to be persisted.
 */
export function partialize(state: ISharedGlobalState) {
  return {
    persistedData: state.persistedData,
  }
}

/**
 * Merge persisted state with current state.
 */
export function merge(
  persistedState: ISharedGlobalState,
  currentState: ISharedGlobalState,
) {
  return {
    ...currentState,
    ...persistedState,
  }
}
