import {
  EnumFunnelTrackingCookies,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'

/**
 *
 */
export function persistLastEngagementTimestamp() {
  const currentDate = new Date()
  const currentUTCDateString = currentDate.toISOString()

  setCookieWithFallback(
    EnumFunnelTrackingCookies.FW_LAST_ENGAGEMENT_TIMESTAMP,
    currentUTCDateString,
  )
}
