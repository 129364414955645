import { logWarning } from '@core/logging'

const PARAM_PREFIX = 'fwparam_'
const SCRIPT_NAMES = [
  'analytics.js',
  'fwn.js',
  'embed-feed.js',
  'live-helper.js',
  'storyblock.js',
]

let parsedAllScripts = false

function warnDuplicatedConfig(configName: string) {
  if (configName === 'webpack') {
    return
  }

  logWarning(`FW: Duplicated config with different value found: ${configName}`)
}

// When there are multiple scripts with different origins, we run into errors such as
// TypeError: (0, $u.kr) is not a function where our build mappings get into conflicts
// Adding a log here so that we are aware if any clients are affected by this
function processScripts(
  scripts: NodeListOf<HTMLScriptElement>,
  configs: Map<string, string>,
  processedScriptTags: Set<HTMLScriptElement>,
) {
  const filteredScripts = Array.from(scripts).filter((script) => {
    const urlWithoutParams = (script.src ?? '').split('?')[0]
    const scriptFilename = urlWithoutParams.split('/').pop()

    return (
      scriptFilename &&
      SCRIPT_NAMES.some((filename) => scriptFilename.startsWith(filename))
    )
  })

  if (filteredScripts.length < 2) {
    return
  }

  try {
    const firstScriptOrigin = new URL(filteredScripts[0].src).origin

    for (let i = 0; i < filteredScripts.length; i++) {
      if (!filteredScripts[i].src) {
        continue
      }

      if (processedScriptTags.has(filteredScripts[i])) {
        continue
      }

      parseScriptTag({
        scriptEl: filteredScripts[i],
        configs,
        processedScriptTags,
        includeNonFWParams: true,
        includeSearchParams: true,
      })

      const currentScriptOrigin = new URL(filteredScripts[i].src).origin

      if (currentScriptOrigin !== firstScriptOrigin) {
        // TODO: We should turn this on but there are a large amount of customers having this kind of implementations
        // const message = `FW: Different origins found for specific scripts: ${filteredScripts[0].src} and ${filteredScripts[i].src}`
        // logWarning(message)
      }
    }
  } catch (e) {
    // Fails silently if any new URL fails
  }
}

/**
 * Extract the param key from the input by removing `fwparam_` prefix if it
 * exists.
 * @param input
 * @returns The param key without `fwparam_` prefix.
 */
function extractParamKey(input: string): string {
  if (input.startsWith(PARAM_PREFIX)) {
    return input.substring(PARAM_PREFIX.length)
  }

  return input
}

function parseScriptTag({
  scriptEl,
  configs,
  processedScriptTags,
  includeNonFWParams = false,
  includeSearchParams = false,
}: {
  scriptEl: HTMLScriptElement
  configs: Map<string, string>
  processedScriptTags: Set<HTMLScriptElement>
  includeNonFWParams?: boolean
  includeSearchParams?: boolean
}) {
  processedScriptTags.add(scriptEl)

  try {
    const scriptSource = scriptEl.src
    const scriptURL = new URL(scriptSource)
    if (scriptURL.search && includeSearchParams) {
      for (const [key, value] of scriptURL.searchParams.entries()) {
        if (configs.has(key) && configs.get(key) !== value) {
          warnDuplicatedConfig(key)
        }
        configs.set(key, value)
      }
    }

    for (const key of Object.keys(scriptEl.dataset)) {
      if (!key.startsWith('fwparam_') && !includeNonFWParams) {
        continue
      }

      const paramKey = extractParamKey(key)
      const value = scriptEl.dataset[key]

      if (configs.has(paramKey) && configs.get(paramKey) !== value) {
        warnDuplicatedConfig(paramKey)
      }
      if (typeof value !== 'undefined') {
        configs.set(paramKey, value)
      }
    }
  } catch (e) {}
}

/**
 * Parses global config on the current script tag and support search param and
 * non fw param for backward compatibility.
 */
export function parseCurrentScriptConfigs(
  configs: Map<string, string>,
  processedScriptTags: Set<HTMLScriptElement>,
) {
  if (
    document.currentScript &&
    'src' in document.currentScript &&
    !processedScriptTags.has(document.currentScript)
  ) {
    parseScriptTag({
      scriptEl: document.currentScript,
      configs,
      processedScriptTags,
      includeNonFWParams: true,
      includeSearchParams: true,
    })
  }
}

/**
 * Parses global config on all the script tags.
 */
export function parseAllScriptConfigs(
  configs: Map<string, string>,
  processedScriptTags: Set<HTMLScriptElement>,
) {
  if (parsedAllScripts) {
    return
  }
  parsedAllScripts = true

  const scripts = document.querySelectorAll('script')
  processScripts(scripts, configs, processedScriptTags)
}

export const TEST_ONLY = {
  reset() {
    parsedAllScripts = false
  },
}
