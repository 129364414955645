import React from 'react'

interface IProps {
  discardOnError?: boolean
  onError?: (error: any) => void
}

interface IState {
  errored: boolean
}

/**
 *
 */
export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<IProps>,
  IState
> {
  override state: IState = { errored: false }

  /**
   *
   */
  override componentDidCatch(error: any): void {
    this.setState({ errored: true })
    this.props.onError && this.props.onError(error)
  }

  /**
   *
   */
  override render(): React.ReactNode {
    if (this.props.discardOnError && this.state.errored) {
      return null
    }
    return this.props.children
  }
}
