import { logInfo, logMessage } from '@core/logging'

import { isIframe } from './iframe'

/**
 * @returns The current page URL. If the current window is in iframe, it will
 * look up page_url from the query params first to work with AMP use case.
 */
export function getPageUrl(): string | undefined {
  try {
    if (isIframe()) {
      const searchParamPageUrl = parseQueryParams().page_url
      if (searchParamPageUrl) {
        return searchParamPageUrl
      }
    }
    return window.location.href
  } catch (e) {
    return
  }
}

/**
 * @returns The string representation of the query param object.
 */
export function stringifyQueryParams(
  obj?: Record<string, unknown>,
  { addQueryPrefix = false } = {},
): string {
  try {
    const qs = new URLSearchParams(obj as Record<string, string>).toString()
    return (addQueryPrefix && qs.length ? '?' : '') + qs
  } catch (err) {
    logMessage(
      'Cant convert querystring to object',
      {
        captureContext: {
          extra: {
            obj: JSON.stringify(obj),
            err,
          },
        },
        originalException: err,
      },
      1,
    )
    return ''
  }
}

/**
 * @param queryString The query string to parse. If not provided, it will use
 * the current window's query string.
 * @returns The object representation of the query string.
 */
export function parseQueryParams(queryString?: string): Record<string, string> {
  const obj: Record<string, string> = {}

  try {
    if (!queryString) {
      queryString = window.location.search
    }
    const params = new URLSearchParams(queryString)

    for (const key of Array.from(params.keys())) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key)[0]
      } else {
        const value = params.get(key)
        if (value) {
          obj[key] = value
        }
      }
    }
  } catch (err) {
    logMessage(
      'Cant convert querystring to object',
      {
        captureContext: {
          extra: {
            queryString,
            err,
          },
        },
        originalException: err,
      },
      1,
    )
  }

  return obj
}

const SENSITIVE_QUERY_PARAMS = ['token']
const SAFE_STRING = 'REPLACED'

/**
 * Replaces sensitive query params with a safe string.
 * @returns URL without sensitive search params.
 */
export function sanitizeSensitiveQueryParams(url: string): string {
  try {
    const urlObj = new URL(url)

    for (const param of SENSITIVE_QUERY_PARAMS) {
      if (urlObj.searchParams.has(param)) {
        urlObj.searchParams.set(param, SAFE_STRING)
      }
    }

    return urlObj.toString()
  } catch (err) {
    logInfo(`Cant sanitize URL: ${url}`)
    return url
  }
}
