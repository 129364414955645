import {
  StorageKeyFunctionalEnum,
  StorageKeyPerformanceEnum,
} from '@core/storage/types'

export enum EnumFunnelTrackingCookies {
  FW_CHAT_CHANNEL_ID = StorageKeyFunctionalEnum.fw_cchid, // The last chat channel ID (Only applicable to OneToOne)
  FW_CHANNEL_ID = StorageKeyFunctionalEnum.fw_chid, // The last channel ID, set at the widget wrapper level to account for all widget types and layouts
  FW_GUEST_ID = StorageKeyFunctionalEnum.fw_uid, // A UUID to keep track of a user between sessions. Helps tie together pixel events for user journeys
  FW_UTM = StorageKeyPerformanceEnum.fw_utm, // The latest UTM params coming from URL search params. Source of truth is URL Search Params => Previously stored UTM params
  FW_LAST_VIDEO_ID = StorageKeyPerformanceEnum.fw_vid, // The last encoded video id a user has watched
  FW_LAST_ENGAGEMENT_TIMESTAMP = StorageKeyPerformanceEnum.fw_lets, // The last engaged timestamp in UTC
  FW_BUSINESS_MEMBERSHIP_ID = StorageKeyFunctionalEnum.fw_bmid, // The last business membership id of a showroom a user has visited
  FW_SHOWROOM_ID = StorageKeyFunctionalEnum.fw_dsid, // The last showroom id a user has visited
  FW_ASSISTANCE_ID_WITH_ANSWER = StorageKeyFunctionalEnum.fw_asidwa, // The last assistance id that an answer video started playing
  FW_LAST_AVA_ENGAGEMENT_WITH_ANSWER_TIMESTAMP = StorageKeyPerformanceEnum.fw_laewats, // The last timestamp in UTC that an answer video started playing
}
export enum EnumUtmKeys {
  SOURCE = 'utm_source',
  SUB_SOURCE = 'utm_subsource',
  MEDIUM = 'utm_medium',
  CAMPAIGN = 'utm_campaign',
  CONTENT = 'utm_content',
}
