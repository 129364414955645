import type { NamedSet } from 'zustand/middleware/devtools'

import type { IDeviceSettingsState } from './state'

export type DeviceSettingsAction = ReturnType<
  typeof createDeviceSettingsActions
>

/**
 *
 */
export function createDeviceSettingsActions(
  set: NamedSet<IDeviceSettingsState>,
) {
  return {
    cameraIdChanged: (value?: string) => {
      set(
        {
          cameraId: value,
        },
        false,
        'cameraIdChanged',
      )
    },
    speakerIdChanged: (value?: string) => {
      set({ speakerId: value }, false, 'speakerIdChanged')
    },
    microphoneIdChanged: (value?: string) => {
      set(
        {
          microphoneId: value,
        },
        false,
        'microphoneIdChanged',
      )
    },
    getCameraIdFailed: () => {
      set({ failedToGetCameraId: true }, false, 'getCameraIdFailed')
    },
    toggleFacingMode: () => {
      set(
        (state) => {
          return {
            facingMode:
              state.facingMode === 'environment' ? 'user' : 'environment',
          }
        },
        false,
        'facingModeChanged',
      )
    },
  }
}
