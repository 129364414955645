import type { Api_Video } from '@core/api'
import type { IAnyWidgetParams } from '@core/types'
import type { IAppContext } from '@embed/components/helpers'
import type { AnyWidgetAppContext } from '@embed/hooks/useEmbedAppContext'

/**
 * Function decorates url with current `origin`,
 * `channel_id`, `video_id` and `guest_id` from context
 * by adding `fwreferrer` prefixed query parameters to
 * the `primary` (canonical) url of an event.
 */
export const decorateWithFWReferrer = (
  url: string,
  {
    video,
    appContext,
    params,
  }: {
    video: Api_Video | null
    appContext: AnyWidgetAppContext | IAppContext
    params?: IAnyWidgetParams
  },
): string => {
  try {
    const baseUrl = new URL(url)

    if (params?.page_url) {
      baseUrl.searchParams.set('fwreferrer-origin', params.page_url)
    }
    if (appContext?.channel?.encoded_id) {
      baseUrl.searchParams.set(
        'fwreferrer-channel-id',
        appContext?.channel?.encoded_id,
      )
    }
    if (video?.encoded_id) {
      baseUrl.searchParams.set('fwreferrer-video-id', video.encoded_id)
    }
    if (appContext?.guestId) {
      baseUrl.searchParams.set('fwreferrer-guest-id', appContext?.guestId)
    }
    // this extra regex is to remove any trailing `=` that gets added to an empty query param
    // some sites may use empty query params to toggle things, so their presence
    // in the url is important, but the trailing `=` can break things for them
    // @see CS-3709
    return baseUrl.href.replace(/=(?=&|$)/gm, '')
  } catch (err) {
    return url
  }
}

/**
 * Function decomposes url to search for `fwreferrer` attribution
 * parameters in the query string.
 */
export const parseFWReferrerFromURL = (
  url: string,
): {
  origin: string | null
  channel_id: string | null
  video_id: string | null
  product_id: string | null
  guest_id: string | null
  business_store_id: string | null
} | null => {
  try {
    const parsed = new URL(url)
    return {
      video_id: parsed.searchParams.get('fwreferrer-video-id'),
      channel_id: parsed.searchParams.get('fwreferrer-channel-id'),
      origin: parsed.searchParams.get('fwreferrer-origin'),
      guest_id: parsed.searchParams.get('fwreferrer-guest-id'),
      product_id: parsed.searchParams.get('fwreferrer-product-id'),
      business_store_id: parsed.searchParams.get(
        'fwreferrer-business-store-id',
      ),
    }
  } catch (e) {
    return null
  }
}
