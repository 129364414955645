import type { FC, ReactElement, ReactNode } from 'react'
import React from 'react'
import { I18nextProvider } from 'react-i18next'

import { ThemeProvider } from '@emotion/react'
import * as Sentry from '@sentry/node'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { NextPage } from 'next/types'
import { Connect, Provider } from 'redux-zero/react'

import ErrorBoundary from '@embed/components/common/ErrorBoundary'
import { resizeThumbnailUrl } from '@embed/components/helpers'
import CookieConsent from '@microsites/components/CookieConsent'
import FontHead from '@microsites/components/FontHead/FontHead'
import { FWNScript } from '@microsites/components/FWNScript'
import i18next from '@microsites/i18next'
import { store } from '@microsites/store'
import type { MicrositeConfig } from '@microsites/types'
import { UITheme } from '@microsites/types'
import type { Api_Page } from '@microsites/types/page'
import { getAnalyticsTags } from '@microsites/utils/microsites/getAnalyticsTags'
import { getMicrositeFont } from '@microsites/utils/microsites/getMicrositeFont'
import { getMicrositeTheme } from '@microsites/utils/microsites/getMicrositeTheme'

import '../../node_modules/bootstrap/dist/css/bootstrap-reboot.css'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    sampleRate: 0.2,
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV || 'unknown',
    integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })],
    ignoreErrors: [
      // related to functionality blocked on user browser, ie no cookies or service workers etc
      /Failed to read the '.*' property from '.*'/gm,
      // TODO temporarily disable, remove after rewrite of player
      'NotAllowedError: play() can only be initiated by a user gesture.',
      'The play() request was interrupted by a call to pause()',
      // Issue from fbevents.js
      "Failed to execute 'postMessage' on 'Window': 2 arguments required, but only 1 present.",
      // Unknown issues
      "Cannot read property 'getReadModeConfig' of undefined",
      "Cannot read property 'getReadModeExtract' of undefined",
      "Cannot read property 'getReadModeRender' of undefined",
      "NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD",
      'NotAllowedError: play() can only be initiated by a user gesture.',
      'AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22',
      'The play() request was interrupted by a call to pause()',
      'SessionError: TypeError: Failed to fetch',
      //Sometimes insertBefore fails when users use Google Translate, it mutates text nodes, and React breaks on the next render.
      "FWN: NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  })
}

interface AnalyticsHeadProps {
  ssrChannel: MicrositeConfig
}

const AnalyticsHead = ({ ssrChannel }: AnalyticsHeadProps) => {
  const { headTags } = getAnalyticsTags(ssrChannel)

  // We use dangerouslySetInnerHTML here so that when it is compiled and the DOM
  // is created, headTags is seen as JS, not as text.
  return headTags ? (
    <script dangerouslySetInnerHTML={{ __html: headTags }} />
  ) : null
}

interface LocaleProviderProps {
  channel: MicrositeConfig
  ssrChannel: MicrositeConfig
  ssrPage: Api_Page
  // children: React.ReactChildren
}

const LocaleProvider = ({
  channel,
  ssrChannel,
  ssrPage,
  children,
}: React.PropsWithChildren<LocaleProviderProps>) => {
  return (
    <I18nextProvider i18n={i18next}>
      <Head>
        <AnalyticsHead ssrChannel={ssrChannel} />
        <title>Firework</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="A New Way to Watch" />
        <meta charSet="utf-8" />
        <meta
          name="google-site-verification"
          content="rtd7Z2cQ6sJCz1QFxyag4xUzbxoADTW0qRyjbvOLZRg"
        />
        <meta
          name="google-site-verification"
          content="_aA9qHWg36ZvS-JlsMng7CcMBPd_PKumTS0UhIawjOs"
        />
        <meta property="al:ios:app_name" content="Firework" />
        <meta property="al:ios:app_store_id" content="1359999964" />
        <meta property="al:android:app_name" content="Firework" />
        <meta property="al:android:package" content="com.loopnow.kamino" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="apple-mobile-web-app-title"
          content={channel?.name ? `${channel.name}'s Stories` : 'Firework'}
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="theme-color" content="#ff3b57" />

        <FontHead
          font={
            ssrPage?.metadata?.config?.ui_font || ssrChannel?.config.ui_font
          }
        />

        <link
          rel="apple-touch-startup-image"
          sizes="512x512"
          href={
            channel?.avatar_url
              ? resizeThumbnailUrl(channel.avatar_url, {
                  size: [1024, 1024],
                  quality: 62,
                  format: 'jpg',
                })
              : `/_next/static/icon_512x512.png`
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href={
            channel?.avatar_url
              ? resizeThumbnailUrl(channel.avatar_url, {
                  size: [1024, 1024],
                  quality: 62,
                  format: 'png',
                })
              : `/_next/static/icon_512x512.png`
          }
        />
      </Head>
      {children}
    </I18nextProvider>
  )
}

const Noop = (page: unknown) => page

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  err: {
    message: string
    name: string
    statusCode: number
  }
}

type AppStoreState = ReturnType<typeof store.getState>

/**
 *
 */
const App: FC<AppPropsWithLayout> = (props) => {
  const { Component, pageProps, err } = props
  const getLayout = Component.getLayout || Noop
  const router = useRouter()

  // Analytics tags
  const { bodyTags } = getAnalyticsTags(pageProps.ssrChannel)

  // Font and Theme setup
  const theme = getMicrositeTheme(pageProps.ssrChannel, pageProps.ssrPage)
  const { fontFromConfig, fontFamily } = getMicrositeFont(
    pageProps.ssrChannel,
    pageProps.ssrPage,
  )

  const isAmpPage = ['/videos/[videoId]/amp'].includes(router.route)
  // One-to-one monitoring page does not show cookie consent.
  const skipConsent = router.route === '/monitoring'

  return (
    <>
      {/* We use dangerouslySetInnerHTML here so that when it is compiled and
      the DOM is created, bodyTags is seen as HTML, not as text */}
      <div dangerouslySetInnerHTML={{ __html: bodyTags }} />
      <ErrorBoundary onError={Sentry.captureException}>
        {!isAmpPage && <FWNScript channel={pageProps.ssrChannel} />}
        <Provider store={store}>
          <ThemeProvider
            theme={{
              theme: theme,
              font: fontFromConfig,
            }}
          >
            <Connect
              mapToProps={(state: AppStoreState) => ({
                params: state.params,
              })}
            >
              {(props: { params: AppStoreState['params'] }) => (
                <LocaleProvider {...props} {...pageProps}>
                  {getLayout(<Component {...props} {...pageProps} err={err} />)}
                  {!skipConsent ? <CookieConsent /> : null}
                </LocaleProvider>
              )}
            </Connect>
          </ThemeProvider>
        </Provider>
        {isAmpPage ? (
          <style jsx global>{`
            amp-story-grid-layer.bottom {
              align-content: flex-end;
              padding-left: 15px;
              padding-right: 15px;
            }
            .avatar {
              border-radius: 50px;
            }
            .button {
              align-items: center;
              background: rgb(248, 77, 95);
              border-radius: 2px;
              border-width: 0px;
              color: rgb(255, 255, 255);
              display: flex;
              justify-content: center;
              min-width: 110px;
              overflow: hidden;
              padding: 10px 36px;
              text-decoration: none;
              white-space: nowrap;
              width: auto;
            }
            .button:hover {
              text-decoration: none;
            }
            .button.cta {
              background-image: linear-gradient(to left, #f78361, #f54b64);
              border-radius: 500px;
              min-width: 50%;
            }
            .button.text {
              font-size: 16px;
              font-weight: 600;
            }
            .column {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .creator-name.text {
              font-size: 16px;
              font-weight: 600;
              margin-top: 2px;
            }
            .creator-row {
              align-items: center;
              display: flex;
              justify-content: flex-start;
            }
            .creator-row > .avatar {
              margin-right: 4px;
            }
            .text {
              color: ${theme === UITheme.LIGHT ? 'black' : 'white'};
              font-family: ${fontFamily};
              font-size: 14px;
            }
            .t2b {
              background-image: linear-gradient(
                to bottom,
                transparent,
                rgb(0, 0, 0)
              );
              background-position: center bottom;
              background-repeat: no-repeat;
            }
            .video-info {
              bottom: 0;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding-bottom: 12px;
              padding-left: 12px;
              padding-right: 12px;
              position: absolute;
            }
            .video-title.text {
              font-size: 16px;
              padding-bottom: 12px;
              width: 100%;
            }
            .video-avatar-button {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
          `}</style>
        ) : (
          <style jsx global>{`
            html,
            body {
              --white: #fff;
              --black: #000;
              font-family: ${fontFamily};
              ${fontFromConfig ? `--fw-font-family: ${fontFamily}` : ''};
              ${theme === UITheme.LIGHT
                ? `
              background-color: var(--white);
              color: var(--black);`
                : `background: var(--black);
              color: var(--white);`}
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              margin: 0;
            }

            button:not(:disabled) {
              cursor: pointer;
            }

            a {
              color: var(${theme === UITheme.LIGHT ? '--black' : '--white'});
              text-decoration: none;
            }

            a:hover {
              color: var(${theme === UITheme.LIGHT ? '--black' : '--white'});
              cursor: pointer;
              text-decoration: underline;
            }

            input[type='search']::-webkit-search-decoration,
            input[type='search']::-webkit-search-cancel-button,
            input[type='search']::-webkit-search-results-button,
            input[type='search']::-webkit-search-results-decoration {
              -webkit-appearance: none;
            }

            [data-reach-menu-popover] {
              z-index: 3;
            }

            ::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
          `}</style>
        )}
      </ErrorBoundary>
    </>
  )
}

export default App
