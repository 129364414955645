import type { Api_Video } from '@core/api'

export const HYDRATION_SLICE_KEY = 'hydration'

export interface IHydrationGlobalStateSlice {
  [HYDRATION_SLICE_KEY]: {
    hydratedVideos: Record<string, Partial<Api_Video>>
    hydrateVideo: (partialVideo: Partial<Api_Video>) => void
  }
}

/**
 *
 */
export function createHydrationSlice(set): IHydrationGlobalStateSlice {
  return {
    [HYDRATION_SLICE_KEY]: {
      hydratedVideos: {},
      hydrateVideo: (partialVideo: Partial<Api_Video>) =>
        set((previousState) => {
          if (!partialVideo.encoded_id) {
            return previousState
          }
          return {
            [HYDRATION_SLICE_KEY]: {
              ...previousState[HYDRATION_SLICE_KEY],
              hydratedVideos: {
                ...previousState[HYDRATION_SLICE_KEY].hydratedVideos,
                [partialVideo.encoded_id]: partialVideo,
              },
            },
          }
        }),
    },
  }
}

/**
 *
 */
export const videoHydratedAction = (store, partialVideo) => {
  store.getState()[HYDRATION_SLICE_KEY].hydrateVideo(partialVideo)
}

/**
 *
 */
export const getHydratedVideosSelector = (state: IHydrationGlobalStateSlice) =>
  state[HYDRATION_SLICE_KEY].hydratedVideos
