import merge from '@core/lodash/merge'
import { getSession } from '@core/tracking'
import { prepareGuestFields } from '@core/tracking/senders/helpers/prepareGuestFields'
import { prepareSessionFieldsLegacy } from '@core/tracking/senders/helpers/prepareSessionFields'

import { sendBeaconLegacy } from './helpers/beacon'
import { getGlobalTrackingFieldsLegacy } from './helpers/globalTrackingFields'

interface ISendTracking {
  url: string
  query?: Record<string, unknown>
  data?: Record<string, unknown>
  host?: string
}

/**
 * Sends tracking to Naboo API backend.
 * @deprecated We should stop sending tracking to Naboo API backend. Use pixel
 * tracking instead.
 */
export const sendTrackingLegacy = async ({
  url: path,
  query = {},
  data = {},
  host,
}: ISendTracking): Promise<Response | boolean | void> => {
  const session = getSession()

  const mergedData = merge<any>(
    {},
    getGlobalTrackingFieldsLegacy(),
    prepareGuestFields(),
    prepareSessionFieldsLegacy(session),
    data,
  )

  return sendBeaconLegacy({ path, query, host, data: mergedData })
}
