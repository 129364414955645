import { lens } from '@dhmk/zustand-lens'

import type { IShoppingAction } from './actions'
import { createShoppingActions } from './actions'
import type { IShoppingState } from './state'
import { createShoppingState } from './state'

export const SHOPPING_SLICE_KEY = 'shopping'

export interface IShoppingGlobalStateSlice {
  [SHOPPING_SLICE_KEY]: IShoppingState & {
    actions: IShoppingAction
  }
}

/**
 *
 */
export function createShoppingSlice(): IShoppingGlobalStateSlice {
  return {
    [SHOPPING_SLICE_KEY]: lens((set) => ({
      ...createShoppingState(),
      actions: createShoppingActions(set),
    })),
  }
}
