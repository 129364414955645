import { useEffect } from 'react'

import { StorageKeyPerformanceEnum } from '@core/storage/types'
import { setCookieWithFallback } from '@core/tracking/funnelTracking'

/**
 * [CS-5465] persist business ID in cookie for shopify analytics
 */
export const useSetBusinessId = (businessId: string | undefined) => {
  useEffect(() => {
    if (businessId) {
      setCookieWithFallback(StorageKeyPerformanceEnum.fw_bid, businessId)
    }
  }, [businessId])
}
