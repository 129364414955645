import type { IShoppingGlobalStateSlice } from './slice'
import { SHOPPING_SLICE_KEY } from './slice'

/**
 *
 */
export function shoppingCartSelector(state: IShoppingGlobalStateSlice) {
  return state[SHOPPING_SLICE_KEY].cart
}

/**
 *
 */
export function shoppingCartCurrencySelector(state: IShoppingGlobalStateSlice) {
  return state[SHOPPING_SLICE_KEY].cartCurrency
}

/**
 *
 */
export function shoppingCartUrlSelector(state: IShoppingGlobalStateSlice) {
  return state[SHOPPING_SLICE_KEY].cartUrl
}

/**
 *
 */
export function shoppingCartTextSelector(state: IShoppingGlobalStateSlice) {
  return {
    cartAddToCartText: state[SHOPPING_SLICE_KEY].cartAddToCartText,
    cartItemInCartText: state[SHOPPING_SLICE_KEY].cartItemInCartText,
  }
}

/**
 *
 */
export function shoppingActionsSelector(state: IShoppingGlobalStateSlice) {
  return state[SHOPPING_SLICE_KEY].actions
}

/**
 *
 */
export function productsHydratedSelector(state: IShoppingGlobalStateSlice) {
  return state[SHOPPING_SLICE_KEY].productsHydrated
}

/**
 *
 */
export function cartUpdatedCallbackSelector(state: IShoppingGlobalStateSlice) {
  return state[SHOPPING_SLICE_KEY].cartUpdatedCallback
}

/**
 *
 */
export function cartDisplayedCallbackSelector(
  state: IShoppingGlobalStateSlice,
) {
  return state[SHOPPING_SLICE_KEY].cartDisplayedCallback
}

/**
 *
 */
export function productsLoadedCallbackSelector(
  state: IShoppingGlobalStateSlice,
) {
  return state[SHOPPING_SLICE_KEY].productsLoadedCallback
}

/**
 *
 */
export function hasPendingCartUpdateSelector(
  state: IShoppingGlobalStateSlice,
): boolean {
  return state[SHOPPING_SLICE_KEY].pendingCartUpdate > 0
}

/**
 *
 */
export function lastCartUpdateSelector(
  state: IShoppingGlobalStateSlice,
): number {
  return state[SHOPPING_SLICE_KEY].lastCartUpdateTs
}
