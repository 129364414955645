import {
  EnumFunnelTrackingCookies,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'

/**
 * Persist the chat channel ID in a cookie.
 */
export function persistChatChannelId(encodedChatChannelId: string) {
  setCookieWithFallback(
    EnumFunnelTrackingCookies.FW_CHAT_CHANNEL_ID,
    encodedChatChannelId,
  )
}
