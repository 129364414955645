import {
  EnumFunnelTrackingCookies,
  EnumUtmKeys,
  getCookieWithFallback,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'
import { parseQueryParams } from '@core/window'

/**
 * Function responsible for merging and persisting UTM attributes
 */
export function persistUTM() {
  const params = parseQueryParams()

  const storedUTMJson = getCookieWithFallback(
    EnumFunnelTrackingCookies.FW_UTM,
  )?.value
  const storedUTM: Record<EnumUtmKeys, string> = storedUTMJson
    ? JSON.parse(storedUTMJson)
    : {}

  // Search params will be the source of truth => cookie => local storage
  const utmParams: Record<EnumUtmKeys, string> = {
    [EnumUtmKeys.SOURCE]:
      params[EnumUtmKeys.SOURCE] ?? storedUTM?.[EnumUtmKeys.SOURCE],
    [EnumUtmKeys.SUB_SOURCE]:
      params[EnumUtmKeys.SUB_SOURCE] ?? storedUTM?.[EnumUtmKeys.SUB_SOURCE],
    [EnumUtmKeys.MEDIUM]:
      params[EnumUtmKeys.MEDIUM] ?? storedUTM?.[EnumUtmKeys.MEDIUM],
    [EnumUtmKeys.CAMPAIGN]:
      params[EnumUtmKeys.CAMPAIGN] ?? storedUTM?.[EnumUtmKeys.CAMPAIGN],
    [EnumUtmKeys.CONTENT]:
      params[EnumUtmKeys.CONTENT] ?? storedUTM?.[EnumUtmKeys.CONTENT],
  }

  // Note: we are not setting the cookie if all values are undefined
  // since empty `{}` value was breaking some customers' gateway (QA-1436).
  if (Object.values(utmParams).filter(Boolean).length > 0) {
    setCookieWithFallback(
      EnumFunnelTrackingCookies.FW_UTM,
      JSON.stringify(utmParams),
    )
  }
}
