import { uuidv4 } from '@expPlatform/embeds/embed/helpers'

import {
  EnumFunnelTrackingCookies,
  getCookieWithFallback,
  setCookieWithFallback,
} from '@core/tracking/funnelTracking'

/**
 *
 */
export function persistGuestId() {
  const guestId =
    getCookieWithFallback(EnumFunnelTrackingCookies.FW_GUEST_ID)?.value ||
    uuidv4()

  if (guestId) {
    setCookieWithFallback(EnumFunnelTrackingCookies.FW_GUEST_ID, guestId)
  }
}
