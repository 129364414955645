import type { FC } from 'react'

import getConfig from 'next/config'
import Script from 'next/script'

import type { IAPI_Channel } from '@core/api'
import { pruneNilValues } from '@core/object'
import { stringifyQueryParams } from '@core/window'

const { publicRuntimeConfig } = getConfig()

interface Props {
  channel: IAPI_Channel['channel'] | undefined
}

/**
 *
 */
export const FWNScript: FC<Props> = ({ channel }) => {
  const { fwnGlobalConfig, fwnPath } = publicRuntimeConfig
  const fwnConfig = pruneNilValues({
    ...fwnGlobalConfig,
    channel: channel?.username,
  })

  return (
    <Script
      src={fwnPath + stringifyQueryParams(fwnConfig, { addQueryPrefix: true })}
      strategy="beforeInteractive"
    />
  )
}
