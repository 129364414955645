import type { Api_Page, MicrositeConfig } from '@microsites/types'
import { UITheme } from '@microsites/types'

export const DEFAULT_THEME = UITheme.DARK // TODO: [ZE-234] change to light when backend default is changed

/**
 * Helper function to get dark/light theme from the page config, or the channel
 * config. If no theme is set, it will return the default theme. We
 */
export const getMicrositeTheme = (
  ssrChannel?: MicrositeConfig,
  ssrPage?: Api_Page,
): UITheme.DARK | UITheme.LIGHT | undefined => {
  return (
    ssrPage?.metadata?.config?.ui_theme ??
    ssrChannel?.config.ui_theme ??
    DEFAULT_THEME
  )
}
