/**
 * keys are ids of videos that have been unlocked
 * values are the last successful access code used by the current user to unlock the video
 * TODO (CS-4065) This will eventually be expanded to store the last correct access code for re-auth
 */
export type IGateUnlockVideoIds = Record<string, string>

/**
 * We store ids we've checked this session so we only re-auth once per session
 */
export type ICheckedVideoIds = Set<string>

export interface IGatedVideoState {
  unlockedVideoIds: IGateUnlockVideoIds
  checkedVideoIds: ICheckedVideoIds
}

/**
 * Global state for gated video
 */
export function createGatedVideoState(): IGatedVideoState {
  return {
    unlockedVideoIds: {},
    checkedVideoIds: new Set(),
  }
}
