import { logError } from '@core/logging'
import { getCookie } from '@core/storage'
import { getRawItem } from '@core/storage/localStorage'
import type { CookieStoredItem } from '@core/tracking/funnelTracking/cookies/types'

/**
 *
 */
export function getCookieWithFallback(
  key: string,
): CookieStoredItem | undefined {
  let fetchedCookie: string | undefined = undefined

  try {
    fetchedCookie = getCookie(key)
    if (fetchedCookie !== undefined) {
      try {
        return JSON.parse(fetchedCookie) as CookieStoredItem
      } catch (e) {
        // it's possible we still have value which is only string,
        // in that case expiration is handled by cookie automatically
        return { value: fetchedCookie }
      }
    }

    // Because we do not control clearing of cookies, if this cookie
    // value is null, we will try and get the value from local storage.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getRawItem<string>(key)
  } catch (error) {
    logError(error)
  }
}
